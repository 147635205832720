.userRegistrationScreen {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  white-space: nowrap;
}

.memberRegistration {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Montserrat, sans-serif;
  color: #1f1f1f;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;

  .background {
    background-color: #e8f6f5;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 145px 80px;

    .container {
      border-radius: 15px;
      background-color: #fff;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      display: flex;
      /* margin-bottom: -25px; */
      /* width: 580px; */
      /* width: 100%; */
      width: 90vw;
      flex-direction: column;
      align-items: center;
      padding: 60px;

      .content {
        display: flex;
        width: 80vw;
        /* max-width: 100%; */
        flex-direction: column;
        align-items: center;
        margin: 10px;

        .logo {
          color: #000;
          font: 16px Inter, sans-serif;
        }

        .message {
          font-size: 15px;
          padding: 11px 15px;
          border: none;
          cursor: pointer;
          width: 50vw;
          text-wrap: wrap;
        }

        .title {
          font-size: 20px;
          margin: 49px;
        }
      }
    }
  }


}

@media (max-width: 991px) {
  .userRegistrationScreen {
    white-space: initial;
  }
}

header {
  width: 100%;
}

.divButton {
  width: 100%;
  justify-items: center;
  display: grid;
}

 

@media (max-width: 991px) {
  .background {
    max-width: 100%;
    white-space: initial;
    padding: 0 20px;
  }
}

.formContainer {
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 580px;
  height: 95%;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px 80px;
}

@media (max-width: 991px) {
  .formContainer {
    white-space: initial;
    padding: 0 20px;
  }
}

.formContent {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 991px) {
  .formContent {
    white-space: initial;
  }
}

.registrationTitle {
  color: #000;
  font-size: 24px;

  text-align: center;
  align-self: center;
  margin-top: 14px;
}

.minf {
  font-size: 9px;
  text-align: center;
  align-self: center;
}
.minf1 {
  font-size: 9px;
}


@media (max-width: 991px) {
  .registrationTitle {
    margin-top: 4px;
  }
}

.labelWrapper {
  display: flex;
  margin-top: 13px;
  gap: 7px;

}

@media (max-width: 991px) {
  .labelWrapper {
    white-space: initial;
  }
}

.inputLabel {
  color: #1f1f1f;
  font-size: 14px;
  margin: 15px 0px 0px 0px;
}

.requiredBadge {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  color: #fff;
  text-align: center;
  /* margin: 10px 0px 0px 0px; */
  padding-top: 14px;
}

@media (max-width: 991px) {
  .requiredBadge {
    white-space: initial;
  }
}

.requiredText {
  border-radius: 50px;
  background-color: #cb3030;
  padding: 0px 5px 2px 5px;
}

@media (max-width: 991px) {
  .requiredText {
    white-space: initial;
  }
}

.inputField {
  border-radius: 5px;
  align-self: stretch;
  display: flex;
  margin-top: 6px;
  height: 40px;
  border: 1px solid #1f1f1f;
  padding: 6px 10px;
}

.passwordHint {
  align-self: flex-start;
  display: flex;
  /* margin-top: 4px; */
  gap: 4px;
  font-size: 12px;
  color: #ef0000;
  justify-content: center;

  .imgmsg {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 24px;
  }

  .divmsg {
    font-family: 'Montserrat', Noto Sans Japanese;
    margin: auto 0;
  }
}

.selectWrapper {
  display: flex;
  margin-top: 6px;
  gap: 6px;
  color: #e4e4e4;
  font: 12px/1 Noto Sans JP, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
  .selectWrapper {
    white-space: initial;
  }
}

.selectField {
  border-radius: 5px;
  display: flex;
  gap: 31px;
  padding: 8px 10px;
  border: 1px solid #1f1f1f;
}

.statusSelect {
  border: 1px solid #dadada;
  border-radius: 5px;
  font-size: 14px;
  height: 40px;
  padding: 6px 10px;
  width: 235px;
  margin-top: 6px;
}


@media (max-width: 991px) {
  .selectField {
    white-space: initial;
  }
}

.dropdownIcon {
  aspect-ratio: 1.4;
  object-fit: contain;
  object-position: center;
  width: 14px;
  align-self: start;
  margin-top: 5px;
}
.submitButtonActive {
  border-radius: 24px;
  background-color: #49b2aa;
  margin-top: 40px;
  margin-bottom: 40px;
  min-width: 261px;
  font-size: 15px;
  color: #fff;
  text-align: center;
  border: none;
  height: 40px;
}
.submitButton {
  border-radius: 24px;
  background-color: rgba(73, 178, 170, 0.6);
  margin-top: 40px;
  margin-bottom: 40px;
  min-width: 261px;
  font-size: 15px;
  color: #fff;
  text-align: center;
  border: none;
  height: 40px;
}

@media (max-width: 991px) {
  .submitButton {
    white-space: initial;
  }
}

.serviceLogo {
  color: #000;
  text-align: center;
  align-self: center;
  font: 22px Inter, sans-serif;
  margin: 10px 10px 20px 10px;
  height: 100%;
  max-width: 350px;
  width: 100%;
}

.serviceLogom {
  color: #000;
  text-align: center;
  align-self: center;
  font: 22px Inter, sans-serif;
  margin: 30px 10px 30px 10px;
  max-width: 300px;
  width: 100%;
}