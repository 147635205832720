.minibar {
    background-color: #49B2AA;
    height: 20vh;
    border-right: 1px solid rgb(208, 208, 208);
    color: #FFFF;
    padding-top: 0px;
    padding-bottom: 0px;
}

.mini {
    // margin-top: 5px;
    cursor: pointer;
    display: flex;
    align-items: end;
    border-radius: 50px 0 0 50px;
}

.active {
    background-color: #1E1E1E;
    /* color: #FFFF; */
}

.hover {
    /* background-color: #1E1E1E; */
    /* border-radius: 50px 0 0 50px; */
    color: #FFFF;
    height: 40px;
}
