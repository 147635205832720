@media (min-width: 992px) {
  .settingpage {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;

    .settingpage-title {
      padding: 30px 15px 30px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .settingpage-heading {
        display: inline-flex;
        align-items: baseline;
        font: 600 16px Inter, sans-serif;

        .minf {
          font-size: 9px;
        }
      }
    }

    .div-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }


    .settingpage-container {
      // align-self: center;
      height: 100%;
      background-color: white;
      margin: 10px;

      .user-info-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .edit-button {
          background-color: #1f1f1f;
          border-radius: 50px;
          align-self: start;
          color: #fff;
          font-weight: 400;
          margin: 25px;
          padding: 10px 20px;
          border: 1px solid #d9d9d9;

          .minf {
            font: 400 9px Inter, sans-serif;
          }
        }

        .user-info-title {
          display: block;
          color: #000;
          font: 600 14px Inter, sans-serif;
          margin: 15px 100px;
          padding: 10px;

          .minf {
            font-size: 9px;
          }

          .user-info-warning {
            color: #EF0000;
            font: 400 11px Inter, sans-serif;

            .minf {
              font: 400 9px Inter, sans-serif;
            }
          }
        }

      }

      .deactivate-button {
        border-radius: 50px;
        background-color: #fff;
        align-self: start;
        color: #1f1f1f;
        font-weight: 400;
        margin: 40px 15px;
        padding: 10px 20px;
        border: 1px solid #d9d9d9;

        .minf {
          font: 400 9px Inter, sans-serif;
        }
      }

      .submit {
        text-align: center;
        padding: 5px;

        .submitButton {
          border-radius: 24px;
          background-color: rgba(73, 178, 170, 0.6);
          align-self: center;
          margin-top: 20px;
          width: 260px;
          font-size: 14px;
          color: #fff;
          padding: 5px 32px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }

        .submitButtonActive {
          border-radius: 24px;
          background-color: #49b2aa;
          margin: 30px;
          width: 260px;
          font-size: 14px;
          color: #fff;
          padding: 5px 30px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }
      }

      .setting5 {
        text-align: start;
        overflow-y: scroll;
        padding: 30px 30px 30px 100px;

        .setting6 {
          padding: 25px;
          text-align: center;

          p {
            color: #000;
          }

          .minf {
            font: 400 9px Inter, sans-serif;
          }
        }

        .setting10 {
          display: flex;
          align-items: center;

          .setting11 {
            color: #434343;
            font: 500 14px "Montserrat";
            margin: 15px;
            width: 300px;
            text-wrap: nowrap;

            .minf {
              font: 400 9px Inter, sans-serif;
            }

            .minf1 {
              font: 400 9px Inter, sans-serif;
            }
          }

          .setting12 {
            color: #212121;
            font-size: 14px;
            flex-grow: 1;
            flex-basis: auto;
            width: 100px;

            .inputField {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 235px;
              border: 1px solid #1f1f1f;
              padding: 6px 10px;
            }

            .statusSelect {
              border: 1px solid #dadada;
              border-radius: 5px;
              font-size: 14px;
              height: 40px;
              padding: 6px 10px;
              width: 235px;
              margin-top: 6px;
            }

            .p1 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p11 {
              color: #212121;
              padding-top: 6px;
              width: 190px;
              // padding-left: 20px;
              text-wrap: wrap;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p12 {
              color: #212121;
              padding-left: 1px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p13 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .div-input {
              border-radius: 10px;
              border-color: rgba(24, 63, 147, 1);
              border-style: solid;
              border-width: 1px;
              color: #212121;
              padding: 8px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }
          }

          .setting14 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 15px;
            width: 300px;
            display: flex;
            align-items: center;

            .setting142 {
              color: #CB3030;
            }

            .setting141 {
              color: #434343;
              font: 400 14px "Montserrat";
              width: 135px;

              .minf {
                font: 400 9px Inter, sans-serif;
                display: block;
              }

              .minf1 {
                font: 400 9px Inter, sans-serif;
              }
            }
          }

          .setting15 {
            text-wrap: wrap;

            // width: 200px;
            .setting16 {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              // position: relative;
              .pp {
                color: #434343;
                font: 400 14px "Montserrat";
                width: 100%;
                overflow-wrap: anywhere;
                width: 260px;
              }

              .inputField1 {
                border-radius: 5px;
                display: flex;
                margin-top: 6px;
                height: 40px;
                width: 100%;
                border: 1px solid #1f1f1f;
                padding: 6px 10px;
              }

              .msg {
                // min-height: 60px; /* Adjusted height to ensure alignment */
                display: block;
                align-items: center;
                // padding: 6px 10px;
                color: red;
                position: relative;
                // width: 235px !important;
                width: 440px;
                margin-top: 5px;
                white-space: normal;
                word-wrap: break-word;
                overflow-wrap: break-word;
              }

              .div-msg {
                margin: 0;
                // width: 80%;
              }
            }
          }
        }

        .setting13 {
          border-color: rgba(217, 217, 217, 1);
          border-width: 1px;
          background-color: #d9d9d9;
          margin: 15px;
          height: 1px;
        }


      }


    }

    .gif-container {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-top: calc(40vh - 60px);
      height: 75vh;

      .loading-gif {
        width: 50px;
        height: 50px;
      }
    }

    /* Button Styles */
    .button {
      padding: 8px 12px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      margin: 0 4px;
      transition: 0.5s;
    }

    /* Form Styles */
    .form-container {
      max-width: 400px;
      margin: auto;
      margin-bottom: 50px;
      padding: 20px;
    }

    .form-group {
      margin-bottom: 15px;
    }

    .form-group label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .form-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      outline: none;
      border-radius: 4px;
      font-size: 14px;
    }

    .form-container form button {
      float: right;
    }

    .input-error {
      border-color: #f44336;
      /* Red */
    }

    .error-message {
      color: #f44336;
      /* Red */
      font-size: 12px;
      margin: 5px 0 0;
    }

    .submit-button {
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background-color: #4caf50;
      /* Green */
      color: white;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .submit-button:hover {
      background-color: #45a049;
      /* Darker Green */
    }

    .action-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .settingpage {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;


    .settingpage-title {
      padding: 20px 15px 20px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .settingpage-heading {
        display: inline-flex;
        align-items: baseline;

        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .div-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }

    .settingpage-container {
      height: 85vh;
      background-color: white;
      margin: 8px;

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }

      .user-info-header {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;

        .edit-button {
          background-color: #1f1f1f;
          color: #fff;
          border-radius: 50px;
          font-weight: 400;
          margin: 20px;
          padding: 5px 30px;
          border: 1px solid #d9d9d9;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
            text-wrap: nowrap;
          }
        }

        .user-info-title {
          align-content: center;
          display: block;
          color: #000;
          margin: 15px 60px;
          font: 600 14px Inter, sans-serif;
          padding: 10px;

          .minf {
            font-size: 9px;
          }

          .user-info-warning {
            color: #EF0000;
            font: 400 11px Inter, sans-serif;

            .minf {
              font: 400 9px Inter, sans-serif;
            }
          }
        }


      }

      .deactivate-button {
        border-radius: 50px;
        background-color: #fff;
        color: #1f1f1f;
        font-weight: 400;
        margin: 50px 8px;
        padding: 5px 30px;
        border: 1px solid #d9d9d9;

        .minf {
          font: 400 9px Inter, sans-serif;
          display: block;
        }
      }

      .submit {
        text-align: center;
        padding: 20px;

        .submitButton {
          border-radius: 24px;
          background-color: rgba(73, 178, 170, 0.6);
          align-self: center;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 32px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }

        .submitButtonActive {
          border-radius: 24px;
          background-color: #49b2aa;
          align-self: center;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 30px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }
      }

      .setting5 {
        text-align: start;
        overflow-y: scroll;
        padding: 50px;

        .setting6 {
          padding: 25px;
          text-align: center;

          p {
            color: #000;
          }

          .minf {
            font: 400 9px Inter, sans-serif;
          }
        }

        .setting10 {
          display: flex;
          align-items: center;
          padding-bottom: 10px;

          .setting11 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 10px;
            width: 200px;
            text-wrap: nowrap;

            .minf {
              font: 400 9px Inter, sans-serif;
              display: block;
            }

            .minf1 {
              font: 400 9px Inter, sans-serif;
            }
          }

          .setting12 {
            color: #212121;
            font-size: 14px;
            flex-grow: 1;
            flex-basis: auto;

            .statusSelect {
              border: 1px solid #dadada;
              border-radius: 5px;
              font-size: 14px;
              height: 40px;
              padding: 6px;
              margin-top: 6px;
            }

            .inputField {
              width: 281px;
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .p1 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p11 {
              color: #212121;
              padding-top: 6px;
              width: 190px;
              // padding-left: 20px;
              text-wrap: wrap;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p12 {
              color: #212121;
              padding-left: 1px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p13 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .div-input {
              border-radius: 10px;
              border-color: rgba(24, 63, 147, 1);
              border-style: solid;
              border-width: 1px;
              color: #212121;
              padding: 8px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }
          }

          .setting14 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 10px;
            width: 200px;
            display: flex;
            align-items: center;

            .setting142 {
              color: #CB3030;
            }

            .setting141 {
              color: #434343;
              font: 400 14px "Montserrat";
              width: 135px;

              .minf {
                font: 400 9px Inter, sans-serif;
                display: block;
              }

              .minf1 {
                font: 400 9px Inter, sans-serif;
              }
            }
          }

          .setting15 {
            text-wrap: wrap;

            .setting16 {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .inputField1 {
                border-radius: 5px;
                display: flex;
                margin-top: 6px;
                height: 40px;
                width: 100%;
                border: 1px solid #1f1f1f;
                padding: 6px;
              }

              .msg {
                // min-height: 60px; /* Adjusted height to ensure alignment */
                display: block;
                align-items: center;
                // padding: 6px;
                color: red;
                position: relative;
                // width: 235px !important;
                width: 350px;
                margin-top: 5px;
                white-space: normal;
                word-wrap: break-word;
                overflow-wrap: break-word;
              }
            }
          }
        }

        .setting13 {
          border-color: rgba(217, 217, 217, 1);
          border-width: 1px;
          background-color: #d9d9d9;
          margin: 10px;
          height: 1px;
        }


      }


    }

    .titleInput,
    .statusSelect {
      border: 1px solid #dadada;
      border-radius: 5px;
      font: 400 14px Inter, sans-serif;
      height: 35px;
      padding: 0 10px;
      width: 280px;
      // z-index: -1;
    }

    .filterLabel {
      margin-right: 10px;
      flex-wrap: nowrap;
      width: 80px;
      text-align: start;
    }

    .resetButton {
      align-items: center;
      background: none;
      border: none;
      color: #000;
      cursor: pointer;
      text-wrap: nowrap;
      font: 400 16px Inter, sans-serif;
    }

    .gif-container {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-top: calc(40vh - 60px);

      .loading-gif {
        width: 50px;
        height: 50px;
      }
    }

    /* Button Styles */
    .button {
      padding: 8px 12px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      margin: 0 4px;
      transition: 0.5s;
    }

    /* Form Styles */
    .form-container {
      max-width: 400px;
      margin: auto;
      margin-bottom: 50px;
      padding: 20px;
    }

    .form-group {
      margin-bottom: 15px;
    }

    .form-group label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .form-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      outline: none;
      border-radius: 4px;
      font-size: 14px;
    }

    .form-container form button {
      float: right;
    }

    .input-error {
      border-color: #f44336;
      /* Red */
    }

    .error-message {
      color: #f44336;
      /* Red */
      font-size: 12px;
      margin: 5px 0 0;
    }

    .submit-button {
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background-color: #4caf50;
      /* Green */
      color: white;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .submit-button:hover {
      background-color: #45a049;
      /* Darker Green */
    }

    .action-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

@media (min-width: 361px) and (max-width: 767px) and (min-height: 896px) {
  .settingpage {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;


    .settingpage-title {
      padding: 20px 15px 20px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .settingpage-heading {
        display: inline-flex;
        align-items: baseline;

        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .div-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }

    .settingpage-container {
      height: 83vh;
      background-color: white;
      margin: 8px;

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }

      .user-info-header {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;

        .edit-button {
          background-color: #1f1f1f;
          color: #fff;
          border-radius: 50px;
          font-weight: 400;
          margin: 8px;
          padding: 5px 20px;
          border: 1px solid #d9d9d9;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
            text-wrap: nowrap;
          }
        }

        .user-info-title {
          align-content: center;
          display: block;
          color: #000;
          margin: 8px;
          font: 600 14px Inter, sans-serif;

          .minf {
            font-size: 9px;
          }

          .user-info-warning {
            color: #EF0000;
            font: 400 12px Inter, sans-serif;

            .minf {
              font-size: 9px;
            }
          }
        }


      }

      .deactivate-button {
        border-radius: 50px;
        background-color: #fff;
        color: #1f1f1f;
        font-weight: 400;
        margin: 50px 8px;
        padding: 5px 30px;
        border: 1px solid #d9d9d9;

        .minf {
          font: 400 9px Inter, sans-serif;
          display: block;
        }
      }

      .submit {
        text-align: center;
        padding: 20px;

        .submitButton {
          border-radius: 24px;
          background-color: rgba(73, 178, 170, 0.6);
          align-self: center;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 32px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }

        .submitButtonActive {
          border-radius: 24px;
          background-color: #49b2aa;
          align-self: center;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 30px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }
      }

      .setting5 {
        text-align: start;
        overflow-y: scroll;

        .setting6 {
          padding: 25px;
          text-align: center;

          p {
            color: #000;
          }

          .minf {
            font: 400 9px Inter, sans-serif;
          }
        }

        .setting10 {
          display: flex;
          align-items: center;
          padding-bottom: 10px;

          .setting11 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 10px;
            width: 135px;
            text-wrap: nowrap;

            .minf {
              font: 400 9px Inter, sans-serif;
              display: block;
            }

            .minf1 {
              font: 400 9px Inter, sans-serif;
            }
          }

          .setting12 {
            color: #212121;
            font-size: 14px;
            flex-grow: 1;
            flex-basis: auto;

            .statusSelect {
              border: 1px solid #dadada;
              border-radius: 5px;
              font-size: 14px;
              height: 40px;
              padding: 6px;
              width: 240px;
              margin-top: 6px;
            }

            .inputField {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 240px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .p1 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p11 {
              color: #212121;
              padding-top: 6px;
              width: 190px;
              // padding-left: 20px;
              text-wrap: wrap;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p12 {
              color: #212121;
              padding-left: 1px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p13 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .div-input {
              border-radius: 10px;
              border-color: rgba(24, 63, 147, 1);
              border-style: solid;
              border-width: 1px;
              color: #212121;
              padding: 8px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }
          }

          .setting14 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 10px;
            width: 135px;
            display: flex;
            align-items: center;

            .setting142 {
              color: #CB3030;
            }

            .setting141 {
              color: #434343;
              font: 400 14px "Montserrat";
              width: 135px;

              .minf {
                font: 400 9px Inter, sans-serif;
                display: block;
              }

              .minf1 {
                font: 400 9px Inter, sans-serif;
              }
            }
          }

          .setting15 {
            text-wrap: wrap;

            .pp {
              color: #434343;
              font: 400 14px "Montserrat";
              width: 250px;
              overflow-wrap: anywhere;
            }

            .inputField1 {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 240px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .msg {
              // min-height: 60px; /* Adjusted height to ensure alignment */
              display: block;
              align-items: center;
              // padding: 6px;
              color: red;
              position: relative;
              // width: 235px !important;
              // margin-top: 5px;
              width: 190px;
              white-space: normal;
              word-wrap: break-word;
              overflow-wrap: break-word;
            }
          }

        }

        .setting13 {
          border-color: rgba(217, 217, 217, 1);
          border-width: 1px;
          background-color: #d9d9d9;
          margin: 10px;
          height: 1px;
        }


      }


    }

    .titleInput,
    .statusSelect {
      border: 1px solid #dadada;
      border-radius: 5px;
      font: 400 14px Inter, sans-serif;
      height: 35px;
      padding: 0 10px;
      width: 280px;
      // z-index: -1;
    }

    .filterLabel {
      margin-right: 10px;
      flex-wrap: nowrap;
      width: 80px;
      text-align: start;
    }

    .resetButton {
      align-items: center;
      background: none;
      border: none;
      color: #000;
      cursor: pointer;
      text-wrap: nowrap;
      font: 400 16px Inter, sans-serif;
    }

    .gif-container {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-top: calc(40vh - 60px);

      .loading-gif {
        width: 50px;
        height: 50px;
      }
    }

    /* Button Styles */
    .button {
      padding: 8px 12px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      margin: 0 4px;
      transition: 0.5s;
    }

    /* Form Styles */
    .form-container {
      max-width: 400px;
      margin: auto;
      margin-bottom: 50px;
      padding: 20px;
    }

    .form-group {
      margin-bottom: 15px;
    }

    .form-group label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .form-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      outline: none;
      border-radius: 4px;
      font-size: 14px;
    }

    .form-container form button {
      float: right;
    }

    .input-error {
      border-color: #f44336;
      /* Red */
    }

    .error-message {
      color: #f44336;
      /* Red */
      font-size: 12px;
      margin: 5px 0 0;
    }

    .submit-button {
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background-color: #4caf50;
      /* Green */
      color: white;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .submit-button:hover {
      background-color: #45a049;
      /* Darker Green */
    }

    .action-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

@media (min-width: 361px) and (max-width: 767px) and (min-height: 844px) and (max-height: 895px) {
  .settingpage {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;


    .settingpage-title {
      padding: 20px 15px 20px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .settingpage-heading {
        display: inline-flex;
        align-items: baseline;

        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .div-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }

    .settingpage-container {
      height: 83vh;
      background-color: white;
      margin: 8px;

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }

      .user-info-header {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;

        .edit-button {
          background-color: #1f1f1f;
          color: #fff;
          border-radius: 50px;
          font-weight: 400;
          margin: 8px;
          padding: 5px 20px;
          border: 1px solid #d9d9d9;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
            text-wrap: nowrap;
          }
        }

        .user-info-title {
          align-content: center;
          display: block;
          color: #000;
          margin: 8px;
          font: 600 14px Inter, sans-serif;

          .minf {
            font-size: 9px;
          }

          .user-info-warning {
            color: #EF0000;
            font: 400 12px Inter, sans-serif;

            .minf {
              font-size: 9px;
            }
          }
        }

      }

      .deactivate-button {
        border-radius: 50px;
        background-color: #fff;
        color: #1f1f1f;
        font-weight: 400;
        margin: 50px 8px;
        padding: 5px 30px;
        border: 1px solid #d9d9d9;

        .minf {
          font: 400 9px Inter, sans-serif;
          display: block;
        }
      }

      .submit {
        text-align: center;
        padding: 20px;

        .submitButton {
          border-radius: 24px;
          background-color: rgba(73, 178, 170, 0.6);
          align-self: center;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 32px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }

        .submitButtonActive {
          border-radius: 24px;
          background-color: #49b2aa;
          align-self: center;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 30px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }
      }

      .setting5 {
        text-align: start;
        overflow-y: scroll;

        .setting6 {
          padding: 25px;
          text-align: center;

          p {
            color: #000;
          }

          .minf {
            font: 400 9px Inter, sans-serif;
          }
        }

        .setting10 {
          display: flex;
          align-items: center;
          padding-bottom: 10px;

          .setting11 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 10px;
            width: 130px;
            text-wrap: nowrap;

            .minf {
              font: 400 9px Inter, sans-serif;
              display: block;
            }

            .minf1 {
              font: 400 9px Inter, sans-serif;
            }
          }

          .setting12 {
            color: #212121;
            font-size: 14px;
            flex-grow: 1;
            flex-basis: auto;

            .statusSelect {
              border: 1px solid #dadada;
              border-radius: 5px;
              font-size: 14px;
              height: 40px;
              padding: 6px;
              width: 220px;
              margin-top: 6px;
            }

            .inputField {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 220px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .p1 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p11 {
              color: #212121;
              padding-top: 6px;
              width: 190px;
              // padding-left: 20px;
              text-wrap: wrap;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p12 {
              color: #212121;
              padding-left: 1px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p13 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .div-input {
              border-radius: 10px;
              border-color: rgba(24, 63, 147, 1);
              border-style: solid;
              border-width: 1px;
              color: #212121;
              padding: 8px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }
          }

          .setting14 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 10px;
            width: 128px;
            display: flex;
            align-items: center;

            .setting142 {
              color: #CB3030;
            }

            .setting141 {
              color: #434343;
              font: 400 14px "Montserrat";
              width: 135px;

              .minf {
                font: 400 9px Inter, sans-serif;
                display: block;
              }

              .minf1 {
                font: 400 9px Inter, sans-serif;
              }
            }
          }

          .setting15 {
            text-wrap: wrap;

            .pp {
              color: #434343;
              font: 400 14px "Montserrat";
              width: 200px;
              overflow-wrap: anywhere;
            }

            .inputField1 {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 220px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .msg {
              // min-height: 60px; /* Adjusted height to ensure alignment */
              display: block;
              align-items: center;
              // padding: 6px;
              color: red;
              position: relative;
              // width: 235px !important;
              // margin-top: 5px;
              width: 190px;
              white-space: normal;
              word-wrap: break-word;
              overflow-wrap: break-word;
            }
          }
        }

        .setting13 {
          border-color: rgba(217, 217, 217, 1);
          border-width: 1px;
          background-color: #d9d9d9;
          margin: 10px;
          height: 1px;
        }


      }


    }

    .titleInput,
    .statusSelect {
      border: 1px solid #dadada;
      border-radius: 5px;
      font: 400 14px Inter, sans-serif;
      height: 35px;
      padding: 0 10px;
      width: 280px;
      // z-index: -1;
    }

    .filterLabel {
      margin-right: 10px;
      flex-wrap: nowrap;
      width: 80px;
      text-align: start;
    }

    .resetButton {
      align-items: center;
      background: none;
      border: none;
      color: #000;
      cursor: pointer;
      text-wrap: nowrap;
      font: 400 16px Inter, sans-serif;
    }

    .gif-container {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-top: calc(40vh - 60px);

      .loading-gif {
        width: 50px;
        height: 50px;
      }
    }

    /* Button Styles */
    .button {
      padding: 8px 12px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      margin: 0 4px;
      transition: 0.5s;
    }

    /* Form Styles */
    .form-container {
      max-width: 400px;
      margin: auto;
      margin-bottom: 50px;
      padding: 20px;
    }

    .form-group {
      margin-bottom: 15px;
    }

    .form-group label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .form-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      outline: none;
      border-radius: 4px;
      font-size: 14px;
    }

    .form-container form button {
      float: right;
    }

    .input-error {
      border-color: #f44336;
      /* Red */
    }

    .error-message {
      color: #f44336;
      /* Red */
      font-size: 12px;
      margin: 5px 0 0;
    }

    .submit-button {
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background-color: #4caf50;
      /* Green */
      color: white;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .submit-button:hover {
      background-color: #45a049;
      /* Darker Green */
    }

    .action-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

@media (min-width: 360px) and (max-width: 767px) and (max-height: 667px) {
  .settingpage {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;


    .settingpage-title {
      padding: 20px 15px 20px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .settingpage-heading {
        display: inline-flex;
        align-items: baseline;

        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .div-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }

    .timer {
      width: 100vw;
    }

    .settingpage-container {
      height: 80vh;
      background-color: white;
      margin: 8px;

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }

      .user-info-header {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;

        .edit-button {
          background-color: #1f1f1f;
          color: #fff;
          border-radius: 50px;
          font-weight: 400;
          margin: 8px;
          padding: 5px 20px;
          border: 1px solid #d9d9d9;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
            text-wrap: nowrap;
          }
        }

        .user-info-title {
          align-content: center;
          display: block;
          color: #000;
          margin: 8px;
          font: 600 14px Inter, sans-serif;

          .minf {
            font-size: 9px;
          }

          .user-info-warning {
            color: #EF0000;
            font: 400 12px Inter, sans-serif;

            .minf {
              font-size: 9px;
            }
          }
        }

      }

      .deactivate-button {
        border-radius: 50px;
        background-color: #fff;
        color: #1f1f1f;
        font-weight: 400;
        margin: 8px;
        padding: 5px 30px;
        border: 1px solid #d9d9d9;

        .minf {
          font: 400 9px Inter, sans-serif;
          display: block;
        }
      }

      .submit {
        text-align: center;
        padding: 20px;

        .submitButton {
          border-radius: 24px;
          background-color: rgba(73, 178, 170, 0.6);
          align-self: center;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 30px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }

        .submitButtonActive {
          border-radius: 24px;
          background-color: #49b2aa;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 30px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }
      }

      .setting5 {
        text-align: start;
        overflow-y: scroll;
        height: 74vh;
        padding-bottom: 25px;

        .setting6 {
          padding: 25px;
          text-align: center;

          p {
            color: #000;
          }

          .minf {
            font: 400 9px Inter, sans-serif;
          }
        }

        .setting10 {
          display: flex;
          align-items: center;
          // padding-bottom: 10px;

          .setting11 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 10px;
            width: 130px;
            text-wrap: nowrap;

            .minf {
              font: 400 9px Inter, sans-serif;
              display: block;
            }

            .minf1 {
              font: 400 9px Inter, sans-serif;
            }
          }

          .setting12 {
            color: #212121;
            font-size: 14px;
            flex-grow: 1;
            flex-basis: auto;

            .statusSelect {
              border: 1px solid #dadada;
              border-radius: 5px;
              font-size: 14px;
              height: 40px;
              padding: 6px;
              width: 200px;
              margin-top: 6px;
            }

            .inputField {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 200px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .p1 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p11 {
              color: #212121;
              padding-top: 6px;
              width: 190px;
              // padding-left: 20px;
              text-wrap: wrap;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p12 {
              color: #212121;
              padding-left: 1px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p13 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .div-input {
              border-radius: 10px;
              border-color: rgba(24, 63, 147, 1);
              border-style: solid;
              border-width: 1px;
              color: #212121;
              padding: 8px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }
          }

          .setting14 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 10px;
            width: 128px;
            display: flex;
            align-items: center;

            .setting142 {
              color: #CB3030;
            }

            .setting141 {
              color: #434343;
              font: 400 14px "Montserrat";
              width: 135px;

              .minf {
                font: 400 9px Inter, sans-serif;
                display: block;
              }

              .minf1 {
                font: 400 9px Inter, sans-serif;
              }
            }
          }

          .setting15 {
            text-wrap: wrap;

            .pp {
              color: #434343;
              font: 400 14px "Montserrat";
              width: 170px;
              overflow-wrap: anywhere;
            }

            .inputField1 {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 200px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .msg {
              // min-height: 60px; /* Adjusted height to ensure alignment */
              display: block;
              align-items: center;
              // padding: 6px;
              color: red;
              position: relative;
              // width: 235px !important;
              // margin-top: 5px;
              width: 190px;
              white-space: normal;
              word-wrap: break-word;
              overflow-wrap: break-word;
            }
          }
        }

        .setting13 {
          border-color: rgba(217, 217, 217, 1);
          border-width: 1px;
          background-color: #d9d9d9;
          margin: 10px;
          height: 1px;
        }


      }


    }


    .titleInput,
    .statusSelect {
      border: 1px solid #dadada;
      border-radius: 5px;
      font: 400 14px Inter, sans-serif;
      height: 35px;
      padding: 0 10px;
      width: 280px;
      // z-index: -1;
    }

    .filterLabel {
      margin-right: 10px;
      flex-wrap: nowrap;
      width: 80px;
      text-align: start;
    }

    .resetButton {
      align-items: center;
      background: none;
      border: none;
      color: #000;
      cursor: pointer;
      text-wrap: nowrap;
      font: 400 16px Inter, sans-serif;
    }

    .gif-container {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-top: calc(40vh - 60px);

      .loading-gif {
        width: 50px;
        height: 50px;
      }
    }



    .draggable {
      cursor: move !important;
      width: 15vw;
    }

    .draggable1 {
      cursor: pointer !important;
      width: 25%;

      .star1 {
        font-size: 14px;
      }
    }

    /* Button Styles */
    .button {
      padding: 8px 12px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      margin: 0 4px;
      transition: 0.5s;
    }

    /* Form Styles */
    .form-container {
      max-width: 400px;
      margin: auto;
      margin-bottom: 50px;
      padding: 20px;
    }

    .form-group {
      margin-bottom: 15px;
    }

    .form-group label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .form-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      outline: none;
      border-radius: 4px;
      font-size: 14px;
    }

    .form-container form button {
      float: right;
    }

    .input-error {
      border-color: #f44336;
      /* Red */
    }

    .error-message {
      color: #f44336;
      /* Red */
      font-size: 12px;
      margin: 5px 0 0;
    }

    .submit-button {
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background-color: #4caf50;
      /* Green */
      color: white;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .submit-button:hover {
      background-color: #45a049;
      /* Darker Green */
    }

    .action-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

@media (min-width: 360px) and (max-width: 767px) and (min-height: 668px) and (max-height: 843px) {
  .settingpage {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;


    .settingpage-title {
      padding: 20px 15px 20px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .settingpage-heading {
        display: inline-flex;
        align-items: baseline;

        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .div-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }

    .timer {
      width: 100vw;
    }

    .settingpage-container {
      height: 80vh;
      background-color: white;
      margin: 8px;

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }

      .user-info-header {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;

        .edit-button {
          background-color: #1f1f1f;
          color: #fff;
          border-radius: 50px;
          font-weight: 400;
          margin: 8px;
          padding: 5px 20px;
          border: 1px solid #d9d9d9;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
            text-wrap: nowrap;
          }
        }

        .user-info-title {
          align-content: center;
          display: block;
          color: #000;
          margin: 8px;
          font: 600 14px Inter, sans-serif;

          .minf {
            font-size: 9px;
          }

          .user-info-warning {
            color: #EF0000;
            font: 400 12px Inter, sans-serif;

            .minf {
              font-size: 9px;
            }
          }
        }

      }

      .deactivate-button {
        border-radius: 50px;
        background-color: #fff;
        color: #1f1f1f;
        font-weight: 400;
        margin: 8px;
        padding: 5px 30px;
        border: 1px solid #d9d9d9;

        .minf {
          font: 400 9px Inter, sans-serif;
          display: block;
        }
      }

      .submit {
        text-align: center;
        padding: 20px;

        .submitButton {
          border-radius: 24px;
          background-color: rgba(73, 178, 170, 0.6);
          align-self: center;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 30px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }

        .submitButtonActive {
          border-radius: 24px;
          background-color: #49b2aa;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 30px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }
      }

      .setting5 {
        text-align: start;
        overflow-y: scroll;
        height: 74vh;
        padding-bottom: 25px;

        .setting6 {
          padding: 25px;
          text-align: center;

          p {
            color: #000;
          }

          .minf {
            font: 400 9px Inter, sans-serif;
          }
        }

        .setting10 {
          display: flex;
          align-items: center;
          // padding-bottom: 10px;

          .setting11 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 10px;
            width: 130px;
            text-wrap: nowrap;

            .minf {
              font: 400 9px Inter, sans-serif;
              display: block;
            }

            .minf1 {
              font: 400 9px Inter, sans-serif;
            }
          }

          .setting12 {
            color: #212121;
            font-size: 14px;
            flex-grow: 1;
            flex-basis: auto;

            .statusSelect {
              border: 1px solid #dadada;
              border-radius: 5px;
              font-size: 14px;
              height: 40px;
              padding: 6px;
              width: 190px;
              margin-top: 6px;
            }

            .inputField {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 190px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .p1 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p11 {
              color: #212121;
              padding-top: 6px;
              width: 190px;
              // padding-left: 20px;
              text-wrap: wrap;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p12 {
              color: #212121;
              padding-left: 1px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p13 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .div-input {
              border-radius: 10px;
              border-color: rgba(24, 63, 147, 1);
              border-style: solid;
              border-width: 1px;
              color: #212121;
              padding: 8px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }
          }

          .setting14 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 10px;
            width: 128px;
            display: flex;
            align-items: center;

            .setting142 {
              color: #CB3030;
            }

            .setting141 {
              color: #434343;
              font: 400 14px "Montserrat";
              width: 135px;

              .minf {
                font: 400 9px Inter, sans-serif;
                display: block;
              }

              .minf1 {
                font: 400 9px Inter, sans-serif;
              }
            }
          }

          .setting15 {
            text-wrap: wrap;

            .pp {
              color: #434343;
              font: 400 14px "Montserrat";
              width: 170px;
              overflow-wrap: anywhere;
            }

            .inputField1 {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 190px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .msg {
              // min-height: 60px; /* Adjusted height to ensure alignment */
              display: block;
              align-items: center;
              // padding: 6px;
              color: red;
              position: relative;
              // width: 235px !important;
              // margin-top: 5px;
              width: 190px;
              white-space: normal;
              word-wrap: break-word;
              overflow-wrap: break-word;
            }
          }
        }

        .setting13 {
          border-color: rgba(217, 217, 217, 1);
          border-width: 1px;
          background-color: #d9d9d9;
          margin: 10px;
          height: 1px;
        }


      }


    }


    .titleInput,
    .statusSelect {
      border: 1px solid #dadada;
      border-radius: 5px;
      font: 400 14px Inter, sans-serif;
      height: 35px;
      padding: 0 10px;
      width: 280px;
      // z-index: -1;
    }

    .filterLabel {
      margin-right: 10px;
      flex-wrap: nowrap;
      width: 80px;
      text-align: start;
    }

    .resetButton {
      align-items: center;
      background: none;
      border: none;
      color: #000;
      cursor: pointer;
      text-wrap: nowrap;
      font: 400 16px Inter, sans-serif;
    }

    .gif-container {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-top: calc(40vh - 60px);

      .loading-gif {
        width: 50px;
        height: 50px;
      }
    }



    .draggable {
      cursor: move !important;
      width: 15vw;
    }

    .draggable1 {
      cursor: pointer !important;
      width: 25%;

      .star1 {
        font-size: 14px;
      }
    }

    /* Button Styles */
    .button {
      padding: 8px 12px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      margin: 0 4px;
      transition: 0.5s;
    }

    /* Form Styles */
    .form-container {
      max-width: 400px;
      margin: auto;
      margin-bottom: 50px;
      padding: 20px;
    }

    .form-group {
      margin-bottom: 15px;
    }

    .form-group label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .form-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      outline: none;
      border-radius: 4px;
      font-size: 14px;
    }

    .form-container form button {
      float: right;
    }

    .input-error {
      border-color: #f44336;
      /* Red */
    }

    .error-message {
      color: #f44336;
      /* Red */
      font-size: 12px;
      margin: 5px 0 0;
    }

    .submit-button {
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background-color: #4caf50;
      /* Green */
      color: white;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .submit-button:hover {
      background-color: #45a049;
      /* Darker Green */
    }

    .action-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

@media (min-width: 345px) and (max-width: 767px) and (max-height: 666px) {
  .settingpage {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;


    .settingpage-title {
      padding: 20px 15px 20px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .settingpage-heading {
        display: inline-flex;
        align-items: baseline;

        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .div-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }

    .timer {
      width: 100vw;
    }

    .settingpage-container {
      height: 80vh;
      background-color: white;
      margin: 8px;

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }

      .user-info-header {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;

        .edit-button {
          background-color: #1f1f1f;
          color: #fff;
          border-radius: 50px;
          font-weight: 400;
          margin: 5px;
          padding: 5px 10px;
          border: 1px solid #d9d9d9;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
            text-wrap: nowrap;
          }
        }

        .user-info-title {
          align-content: center;
          display: block;
          color: #000;
          margin: 8px;
          font: 600 14px Inter, sans-serif;

          .minf {
            font-size: 9px;
          }

          .user-info-warning {
            color: #EF0000;
            font: 400 12px Inter, sans-serif;

            .minf {
              font-size: 9px;
            }
          }
        }

      }

      .deactivate-button {
        border-radius: 50px;
        background-color: #fff;
        color: #1f1f1f;
        font-weight: 400;
        margin: 8px;
        padding: 5px 30px;
        border: 1px solid #d9d9d9;

        .minf {
          font: 400 9px Inter, sans-serif;
          display: block;
        }
      }

      .submit {
        text-align: center;
        padding: 20px;

        .submitButton {
          border-radius: 24px;
          background-color: rgba(73, 178, 170, 0.6);
          align-self: center;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 32px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }

        .submitButtonActive {
          border-radius: 24px;
          background-color: #49b2aa;
          align-self: center;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 30px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }
      }

      .setting5 {
        text-align: start;
        overflow-y: scroll;
        height: 70vh;
        padding-bottom: 20px;

        .setting6 {
          padding: 25px;
          text-align: center;

          p {
            color: #000;
          }

          .minf {
            font: 400 9px Inter, sans-serif;
          }
        }

        .setting10 {
          display: flex;
          align-items: center;
          padding-bottom: 10px;

          .setting11 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 10px;
            width: 125px;
            text-wrap: nowrap;

            .minf {
              font: 400 9px Inter, sans-serif;
              display: block;
            }

            .minf1 {
              font: 400 9px Inter, sans-serif;
            }
          }

          .setting12 {
            color: #212121;
            font-size: 14px;
            flex-grow: 1;
            flex-basis: auto;

            .statusSelect {
              border: 1px solid #dadada;
              border-radius: 5px;
              font-size: 14px;
              height: 40px;
              padding: 6px;
              width: 195px;
              margin-top: 6px;
            }

            .inputField {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 195px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .p1 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p11 {
              color: #212121;
              // padding-top: 6px;
              width: 160px;
              // padding-left: 20px;
              text-align: start;
              text-wrap: wrap;
              font: 400 14px 'Montserrat';
            }

            .p12 {
              color: #212121;
              padding-left: 1px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p13 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .div-input {
              border-radius: 10px;
              border-color: rgba(24, 63, 147, 1);
              border-style: solid;
              border-width: 1px;
              color: #212121;
              padding: 8px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }
          }

          .setting14 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 10px;
            width: 125px;
            display: flex;
            align-items: center;

            .setting142 {
              color: #CB3030;
            }

            .setting141 {
              color: #434343;
              font: 400 14px "Montserrat";
              width: 135px;

              .minf {
                font: 400 9px Inter, sans-serif;
                display: block;
              }

              .minf1 {
                font: 400 9px Inter, sans-serif;
              }
            }
          }

          .setting15 {
            text-wrap: wrap;

            .pp {
              color: #434343;
              font: 400 14px "Montserrat";
              overflow-wrap: anywhere;
            }

            .inputField1 {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 195px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .msg {
              // min-height: 60px; /* Adjusted height to ensure alignment */
              display: block;
              align-items: center;
              // padding: 6px;
              color: red;
              position: relative;
              // width: 235px !important;
              // margin-top: 5px;
              width: 190px;
              white-space: normal;
              word-wrap: break-word;
              overflow-wrap: break-word;
            }
          }

        }

        .setting13 {
          border-color: rgba(217, 217, 217, 1);
          border-width: 1px;
          background-color: #d9d9d9;
          margin: 5px;
          height: 1px;
        }


      }


    }


    .titleInput,
    .statusSelect {
      border: 1px solid #dadada;
      border-radius: 5px;
      font: 400 14px Inter, sans-serif;
      height: 35px;
      padding: 0 10px;
      width: 280px;
      // z-index: -1;
    }

    .filterLabel {
      margin-right: 10px;
      flex-wrap: nowrap;
      width: 80px;
      text-align: start;
    }

    .resetButton {
      align-items: center;
      background: none;
      border: none;
      color: #000;
      cursor: pointer;
      text-wrap: nowrap;
      font: 400 16px Inter, sans-serif;
    }

    .gif-container {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-top: calc(40vh - 60px);

      .loading-gif {
        width: 50px;
        height: 50px;
      }
    }



    .draggable {
      cursor: move !important;
      width: 15vw;
    }

    .draggable1 {
      cursor: pointer !important;
      width: 25%;

      .star1 {
        font-size: 14px;
      }
    }

    /* Button Styles */
    .button {
      padding: 8px 12px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      margin: 0 4px;
      transition: 0.5s;
    }

    /* Form Styles */
    .form-container {
      max-width: 400px;
      margin: auto;
      margin-bottom: 50px;
      padding: 20px;
    }

    .form-group {
      margin-bottom: 15px;
    }

    .form-group label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .form-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      outline: none;
      border-radius: 4px;
      font-size: 14px;
    }

    .form-container form button {
      float: right;
    }

    .input-error {
      border-color: #f44336;
      /* Red */
    }

    .error-message {
      color: #f44336;
      /* Red */
      font-size: 12px;
      margin: 5px 0 0;
    }

    .submit-button {
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background-color: #4caf50;
      /* Green */
      color: white;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .submit-button:hover {
      background-color: #45a049;
      /* Darker Green */
    }

    .action-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

@media (min-width: 321px) and (max-width: 344px) {
  .settingpage {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;


    .settingpage-title {
      padding: 20px 15px 20px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .settingpage-heading {
        display: inline-flex;
        align-items: baseline;

        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .div-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }

    .timer {
      width: 100vw;
    }

    .settingpage-container {
      height: 80vh;
      background-color: white;
      margin: 8px;

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }

      .user-info-header {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;

        .edit-button {
          background-color: #1f1f1f;
          color: #fff;
          border-radius: 50px;
          font-weight: 400;
          margin: 5px;
          padding: 5px 10px;
          border: 1px solid #d9d9d9;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
            text-wrap: nowrap;
          }
        }

        .user-info-title {
          align-content: center;
          display: block;
          color: #000;
          margin: 8px;
          font: 600 14px Inter, sans-serif;

          .minf {
            font-size: 9px;
          }

          .user-info-warning {
            color: #EF0000;
            font: 400 12px Inter, sans-serif;

            .minf {
              font-size: 9px;
            }
          }
        }

      }

      .deactivate-button {
        border-radius: 50px;
        background-color: #fff;
        color: #1f1f1f;
        font-weight: 400;
        margin: 8px;
        padding: 5px 30px;
        border: 1px solid #d9d9d9;

        .minf {
          font: 400 9px Inter, sans-serif;
          display: block;
        }
      }

      .submit {
        text-align: center;
        padding: 20px;

        .submitButton {
          border-radius: 24px;
          background-color: rgba(73, 178, 170, 0.6);
          align-self: center;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 32px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }

        .submitButtonActive {
          border-radius: 24px;
          background-color: #49b2aa;
          align-self: center;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 30px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }
      }

      .setting5 {
        text-align: start;
        overflow-y: scroll;
        height: 70vh;
        padding-bottom: 20px;

        .setting6 {
          padding: 25px;
          text-align: center;

          p {
            color: #000;
          }

          .minf {
            font: 400 9px Inter, sans-serif;
          }
        }

        .setting10 {
          display: flex;
          align-items: center;
          padding-bottom: 10px;

          .setting11 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 10px;
            width: 125px;
            text-wrap: nowrap;

            .minf {
              font: 400 9px Inter, sans-serif;
              display: block;
            }

            .minf1 {
              font: 400 9px Inter, sans-serif;
            }
          }

          .setting12 {
            color: #212121;
            font-size: 14px;
            flex-grow: 1;
            flex-basis: auto;

            .statusSelect {
              border: 1px solid #dadada;
              border-radius: 5px;
              font-size: 14px;
              height: 40px;
              padding: 6px;
              width: 175px;
              margin-top: 6px;
            }

            .inputField {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 175px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .p1 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p11 {
              color: #212121;
              // padding-top: 6px;
              width: 160px;
              // padding-left: 20px;
              text-align: start;
              text-wrap: wrap;
              font: 400 14px 'Montserrat';
            }

            .p12 {
              color: #212121;
              padding-left: 1px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p13 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .div-input {
              border-radius: 10px;
              border-color: rgba(24, 63, 147, 1);
              border-style: solid;
              border-width: 1px;
              color: #212121;
              padding: 8px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }
          }

          .setting14 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 10px;
            width: 125px;
            display: flex;
            align-items: center;

            .setting142 {
              color: #CB3030;
            }

            .setting141 {
              color: #434343;
              font: 400 14px "Montserrat";
              width: 135px;

              .minf {
                font: 400 9px Inter, sans-serif;
                display: block;
              }

              .minf1 {
                font: 400 9px Inter, sans-serif;
              }
            }
          }

          .setting15 {
            text-wrap: wrap;

            .pp {
              color: #434343;
              font: 400 14px "Montserrat";
              width: 350px;
              overflow-wrap: anywhere;
            }

            .inputField1 {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 185px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .msg {
              // min-height: 60px; /* Adjusted height to ensure alignment */
              display: block;
              align-items: center;
              // padding: 6px;
              color: red;
              position: relative;
              // width: 235px !important;
              // margin-top: 5px;
              width: 190px;
              white-space: normal;
              word-wrap: break-word;
              overflow-wrap: break-word;
            }
          }

        }

        .setting13 {
          border-color: rgba(217, 217, 217, 1);
          border-width: 1px;
          background-color: #d9d9d9;
          margin: 5px;
          height: 1px;
        }


      }


    }


    .titleInput,
    .statusSelect {
      border: 1px solid #dadada;
      border-radius: 5px;
      font: 400 14px Inter, sans-serif;
      height: 35px;
      padding: 0 10px;
      width: 280px;
      // z-index: -1;
    }

    .filterLabel {
      margin-right: 10px;
      flex-wrap: nowrap;
      width: 80px;
      text-align: start;
    }

    .resetButton {
      align-items: center;
      background: none;
      border: none;
      color: #000;
      cursor: pointer;
      text-wrap: nowrap;
      font: 400 16px Inter, sans-serif;
    }

    .gif-container {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-top: calc(40vh - 60px);

      .loading-gif {
        width: 50px;
        height: 50px;
      }
    }



    .draggable {
      cursor: move !important;
      width: 15vw;
    }

    .draggable1 {
      cursor: pointer !important;
      width: 25%;

      .star1 {
        font-size: 14px;
      }
    }

    /* Button Styles */
    .button {
      padding: 8px 12px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      margin: 0 4px;
      transition: 0.5s;
    }

    /* Form Styles */
    .form-container {
      max-width: 400px;
      margin: auto;
      margin-bottom: 50px;
      padding: 20px;
    }

    .form-group {
      margin-bottom: 15px;
    }

    .form-group label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .form-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      outline: none;
      border-radius: 4px;
      font-size: 14px;
    }

    .form-container form button {
      float: right;
    }

    .input-error {
      border-color: #f44336;
      /* Red */
    }

    .error-message {
      color: #f44336;
      /* Red */
      font-size: 12px;
      margin: 5px 0 0;
    }

    .submit-button {
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background-color: #4caf50;
      /* Green */
      color: white;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .submit-button:hover {
      background-color: #45a049;
      /* Darker Green */
    }

    .action-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 320px) {
  .settingpage {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;


    .settingpage-title {
      padding: 20px 15px 20px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .settingpage-heading {
        display: inline-flex;
        align-items: baseline;

        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .div-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }

    .timer {
      width: 100vw;
    }

    .settingpage-container {
      height: 80vh;
      background-color: white;
      margin: 8px;

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }

      .user-info-header {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;

        .edit-button {
          background-color: #1f1f1f;
          color: #fff;
          border-radius: 50px;
          font-weight: 400;
          margin: 5px;
          padding: 5px 10px;
          border: 1px solid #d9d9d9;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
            text-wrap: nowrap;
          }
        }

        .user-info-title {
          align-content: center;
          display: block;
          color: #000;
          margin: 8px;
          font: 600 14px Inter, sans-serif;

          .minf {
            font-size: 9px;
          }

          .user-info-warning {
            color: #EF0000;
            font: 400 12px Inter, sans-serif;

            .minf {
              font-size: 9px;
            }
          }
        }

      }

      .deactivate-button {
        border-radius: 50px;
        background-color: #fff;
        color: #1f1f1f;
        font-weight: 400;
        margin: 8px;
        padding: 5px 30px;
        border: 1px solid #d9d9d9;

        .minf {
          font: 400 9px Inter, sans-serif;
          display: block;
        }
      }

      .submit {
        text-align: center;
        padding: 20px;

        .submitButton {
          border-radius: 24px;
          background-color: rgba(73, 178, 170, 0.6);
          align-self: center;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 32px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }

        .submitButtonActive {
          border-radius: 24px;
          background-color: #49b2aa;
          align-self: center;
          margin: 20px;
          width: 220px;
          font-size: 14px;
          color: #fff;
          padding: 5px 30px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }
      }

      .setting5 {
        text-align: start;
        overflow-y: scroll;
        height: 70vh;
        padding-bottom: 20px;

        .setting6 {
          padding: 25px;
          text-align: center;

          p {
            color: #000;
          }

          .minf {
            font: 400 9px Inter, sans-serif;
          }
        }

        .setting10 {
          display: flex;
          align-items: center;
          padding-bottom: 10px;

          .setting11 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 5px;
            width: 125px;
            text-wrap: nowrap;

            .minf {
              font: 400 9px Inter, sans-serif;
              display: block;
            }

            .minf1 {
              font: 400 9px Inter, sans-serif;
            }
          }

          .setting12 {
            color: #212121;
            font-size: 14px;
            flex-grow: 1;
            flex-basis: auto;

            .statusSelect {
              border: 1px solid #dadada;
              border-radius: 5px;
              font-size: 14px;
              height: 40px;
              padding: 6px;
              width: 160px;
              margin-top: 6px;
            }

            .inputField {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 160px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .p1 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p11 {
              color: #212121;
              // padding-top: 6px;
              width: 160px;
              // padding-left: 20px;
              text-align: start;
              text-wrap: wrap;
              font: 400 14px 'Montserrat';
            }

            .p12 {
              color: #212121;
              padding-left: 1px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .p13 {
              color: #212121;
              text-align: start;
              font: 400 14px 'Montserrat';
            }

            .div-input {
              border-radius: 10px;
              border-color: rgba(24, 63, 147, 1);
              border-style: solid;
              border-width: 1px;
              color: #212121;
              padding: 8px;
              text-align: start;
              font: 400 14px 'Montserrat';
            }
          }

          .setting14 {
            color: #434343;
            font: 400 14px "Montserrat";
            margin: 5px;
            width: 125px;
            display: flex;
            align-items: center;

            .setting142 {
              color: #CB3030;
            }

            .setting141 {
              color: #434343;
              font: 400 14px "Montserrat";
              width: 135px;

              .minf {
                font: 400 9px Inter, sans-serif;
                display: block;
              }

              .minf1 {
                font: 400 9px Inter, sans-serif;
              }
            }
          }

          .setting15 {
            text-wrap: wrap;

            .pp {
              color: #434343;
              font: 400 14px "Montserrat";
              overflow-wrap: anywhere;
            }

            .inputField1 {
              border-radius: 5px;
              display: flex;
              margin-top: 6px;
              height: 40px;
              width: 160px;
              border: 1px solid #1f1f1f;
              padding: 6px;
            }

            .msg {
              // min-height: 60px; /* Adjusted height to ensure alignment */
              display: block;
              align-items: center;
              // padding: 6px;
              color: red;
              position: relative;
              // width: 235px !important;
              // margin-top: 5px;
              width: 160px;
              white-space: normal;
              word-wrap: break-word;
              overflow-wrap: break-word;
            }
          }

        }

        .setting13 {
          border-color: rgba(217, 217, 217, 1);
          border-width: 1px;
          background-color: #d9d9d9;
          margin: 5px;
          height: 1px;
        }


      }


    }


    .titleInput,
    .statusSelect {
      border: 1px solid #dadada;
      border-radius: 5px;
      font: 400 14px Inter, sans-serif;
      height: 35px;
      padding: 0 10px;
      width: 280px;
      // z-index: -1;
    }

    .filterLabel {
      margin-right: 10px;
      flex-wrap: nowrap;
      width: 80px;
      text-align: start;
    }

    .resetButton {
      align-items: center;
      background: none;
      border: none;
      color: #000;
      cursor: pointer;
      text-wrap: nowrap;
      font: 400 16px Inter, sans-serif;
    }

    .gif-container {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-top: calc(40vh - 60px);

      .loading-gif {
        width: 50px;
        height: 50px;
      }
    }



    .draggable {
      cursor: move !important;
      width: 15vw;
    }

    .draggable1 {
      cursor: pointer !important;
      width: 25%;

      .star1 {
        font-size: 14px;
      }
    }

    /* Button Styles */
    .button {
      padding: 8px 12px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      margin: 0 4px;
      transition: 0.5s;
    }

    /* Form Styles */
    .form-container {
      max-width: 400px;
      margin: auto;
      margin-bottom: 50px;
      padding: 20px;
    }

    .form-group {
      margin-bottom: 15px;
    }

    .form-group label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .form-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      outline: none;
      border-radius: 4px;
      font-size: 14px;
    }

    .form-container form button {
      float: right;
    }

    .input-error {
      border-color: #f44336;
      /* Red */
    }

    .error-message {
      color: #f44336;
      /* Red */
      font-size: 12px;
      margin: 5px 0 0;
    }

    .submit-button {
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background-color: #4caf50;
      /* Green */
      color: white;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .submit-button:hover {
      background-color: #45a049;
      /* Darker Green */
    }

    .action-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}