.sidebar {
    background-color: #49b2aa;
    border-right: 1px solid #d0d0d0;
    box-shadow: 0 10px 10px #00000040;
    color: #fff;
    flex: 1 1;
    font: 400 16px Inter, sans-serif;
    max-width: 220px;
    min-width: 15rem;

    .sidebar-menu {
        padding: 0 10px 0 10px;
        min-height: 90vh;
    }

    .title {
        font-size: 13px;
        color: rgb(187, 186, 186);
    }

    .sidebar-list {
        list-style: none;
        min-height: 90vh;
        padding: 0 10px 0 10px;
    }

    .sidebar-icon {
        font-size: 18px;
        margin-top: 5px;
        padding: 10px 10px 10px 20px;
        cursor: pointer;
        display: flex;
        align-items: baseline;
        border-radius: 50px 0 0 50px;
    }

    .mini {
        font-size: 9px;
        margin-left: 5px;
    }

    .sidebar-icon.active {
        background-color: #1E1E1E;
        /* color: #FFFF; */
    }

    .sidebar-icon:hover {
        background-color: #1E1E1E;
        /* color: #1E1E1E; */
    }

    .icon {
        margin-right: 5px;
        font-size: 20px;
    }

    link {
        text-decoration: none;
        color: inherit;
    }

    .sidebar-enter {
        transform: translateX(-100%);
    }

    .sidebar-enter-active {
        transform: translateX(0);
        transition: transform 600ms;
    }

    .sidebar-exit {
        transform: translateX(0);
    }

    .sidebar-exit-active {
        transform: translateX(-100%);
        transition: transform 600ms;
    }
}