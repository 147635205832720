.movie {
  display: flex;
  flex-direction: column;
  flex: 2;
  overflow: auto;

  .table-title {
    padding: 20px;
  }

  .div-line {
    background-color: #000;
    border-color: #3498db;
    /* border-width: 1px; */
    height: 1px;
    width: 100%;
    position: fixed;
    margin-top: 65px;
    margin-left: 10px;
  }

  .video-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    overflow-y: scroll;
    background-color: #F6F6F6 !important;

    .VideoPlayer {
      position: relative;
      video {
        width: 100%;
        max-width: 700px;
      }
    }

    .video-left {
      margin: 0 10px 10px 10px;
      background: #fff;
      display: flex;
      flex-direction: row;
      flex: 2;
      min-height: 200px;
    }

  }

  .video-container22 {
    display: flex;
    flex-wrap: wrap;
    background-color: #F6F6F6 !important;
    margin: 10px 10px 0 10px;
    min-height: 200px;
    .video-bottom {
      background: #fff;
      min-height: 200px;
      position: absolute;
      display: flex;
      flex-direction: row;
    }
  }

}