.loginPage {
  max-width: none !important;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: #1f1f1f;
  font: 400 14px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
}

.background {
  align-items: center;
  min-height: 100vh;
  height: auto;
  justify-content: center;
  overflow: scroll;
  padding: 10px;
}

.loginContainer {
  border-radius: 15px;
  background-color: #fff;
  display: flex;
  padding: 40px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
}

.formWrapper {
  margin: 10px;
  padding: 10px;
  display: flex;
  height: 100%;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 400px;

  .gif-container {
    display: flex;
    height: 65vh;
    justify-content: center;
    align-items: center;

    .loading-gif {
      width: 50px;
      height: 50px;
    }
  }
}

.serviceLogo {
  color: #000;
  text-align: center;
  align-self: center;
  font: 22px Inter, sans-serif;
  margin: 10px;
  height: 100%;
  width: 100%;
}

.serviceLogom {
  color: #000;
  text-align: center;
  align-self: center;
  font: 22px Inter, sans-serif;
  margin: 15px 10px 15px 10px;
  // height: 100%;
  width: 100%;
}

.serviceLogom1 {
  color: #000;
  text-align: center;
  align-self: center;
  font: 22px Inter, sans-serif;
  margin: 15px 10px 15px 10px;
  // height: 100%;
  width: 100%;
}

.inputLabel {
  align-self: start;
  margin: 10px 5px 5px 5px;
  padding-top: 5px;
}

.inputField {
  border-radius: 5px;
  margin-top: 6px;
  color: #1f1f1f;
  padding: 7px 15px;
  border: 1px solid #1f1f1f;
  height: 35px !important;
}

.yeaIcon {
  margin: -18px 10px 0 0;
  align-self: self-end;
}

.msg {
  align-self: start;
  display: flex;
  margin-top: 5px;
  gap: 4px;
  font-size: 12px;
  color: #ef0000;
  justify-content: start;

  .img-msg {
    aspect-ratio: 1;
    width: 24px;
  }

  .div-msg {
    font-family: 'Montserrat', Noto Sans Japanese;
    margin: auto 0;
  }
}

.msg1 {
  align-self: start;
  display: flex;
  margin-top: 20px;
  gap: 4px;
  font-size: 12px;
  color: #ef0000;
  justify-content: start;
  min-width: 300px;

  .img-msg1 {
    aspect-ratio: 1;
    width: 24px;
  }

  .div-msg1 {
    font-family: 'Montserrat', Noto Sans Japanese;
    margin: auto 0;
    display: flex;

    .mini {
      font-size: 9px;
    }
  }
}

.forgotPassword {
  font-weight: 500;
  text-align: center;
  align-self: center;
  margin: 20px 0px 20px 0px;
  width: 100%;
  display: flex;

  .mini {
    font-size: 9px;
  }
}

.note {
  margin: 5px;
  font: 400 12px Inter, sans-serif;

  .label {
    margin-left: 10px;
  }
}

.note1 {
  margin: 5px;
}

.loginButton {
  border-radius: 24px;
  background-color: #49b2aa;
  align-self: center;
  margin: 10px;
  height: 40px;
  // width: 290px;
  width: 100%;
  font-size: 15px;
  color: #fff;
  padding: 10px 30px;
  border: none;
}

.registerButton {
  border-radius: 24px;
  background-color: #fff;
  height: 40px;
  align-items: center;
  width: 100%;
  color: #49b2aa;
  border: 1px solid #49b2aa;
  display: flex;
  justify-content: center;
  margin: 10px 0px 10px 0px;
  padding: 10px;

  .mini {
    font-size: 9px;
  }
}

@media (min-width: 360px) and (max-height: 740px) {
  .formWrapper {
    width: 400px;
  }

  .serviceLogom1 {
    margin: 5px 10px 5px 10px;
  }
}

@media (min-width: 320px) and (max-height: 667px) {
  .formWrapper {
    width: 375px;
  }

  .serviceLogom {
    padding-top: 40px;
    margin: 30px 10px 5px 10px;
  }

  .serviceLogom1 {
    margin: 5px 5px 5px 5px;
  }
}

@media (max-width: 360px) {
  .forgotPassword {
    font: 12px Inter, sans-serif;
    .mini {
      font-size: 8px;
    }
  }
}

@media (max-width: 991px) {
  .background {
    max-width: 100%;
    padding: 10px;
    height: auto;
    overflow-y: scroll;
  }

  .loginContainer {
    margin: 10px;
    padding: 10px;
    height: auto;
  }

  .formWrapper {
    .gif-container {
      padding-top: calc(20vh - 60px);
    }
  }

  .forgotPassword {
    font-weight: 500;
  }

  .loginButton {
    padding: 10px 20px;
  }

  .registerButton {
    padding: 11px 20px;
  }

  .inputLabel {
    margin: 10px 5px 5px 5px;
    padding-top: 5px;
  }

  .inputField {
    padding-right: 20px;
  }

  .msg1 {
    font-size: 11px;
    min-width: 300px;

    .img-msg1 {
      aspect-ratio: 1;
      width: 24px;
    }

    .div-msg1 {
      display: flex;

      .mini {
        font-size: 8px;
      }
    }
  }

  .msg {
    font-size: 11px;

    .img-msg {
      aspect-ratio: 1;
    }

    .div-msg {
      font-family: 'Montserrat', Noto Sans Japanese;
    }
  }

}

@media (max-width: 820px) {
  .loginContainer {
    margin: 10px;
    padding: 10px;
    width: 500px;
  }
}

@media (max-width: 768px) {
  .loginContainer {
    margin: 10px;
    padding: 10px;
    width: 500px;
  }
}

@media (max-width: 430px) {
  .note {
    font: 400 11px Inter, sans-serif;
  }

  .loginContainer {
    margin: 8px;
    padding: 8px;
  }

  // .serviceLogom1 {
  //   padding-top: 20px;
  //   height: 165px;
  //   margin: 50px 10px 5px 10px;
  // }
}

@media (min-width: 992px) {
  .background {
    height: 100vh;
    height: auto;
  }
  .formWrapper {
    margin: 10px;
    width: 400px;
  }

  .note {
    font: 400 13px Inter, sans-serif;
  }
}

@media (max-width: 320px) {
  .note {
    font: 400 9px Inter, sans-serif;
  }
}