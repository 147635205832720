.testDetails {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.background {
  background-color: #e8f6f5;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.contentWrapper {
  /* z-index: 10; */
  margin-top: -70px;
  width: 100%;
  max-width: 1419px;
}

.twoColumnLayout {
  gap: 20px;
  display: flex;
}

.mainColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 84%;
  margin-left: 20px;
}

.testTitle {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  align-self: start;
}

.divider {
  background-color: #1f1f1f;
  display: flex;
  margin-top: 19px;
  width: 100;
  /* max-width: 100%; */
  height: 1px;
}

.testCard {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  /* height: 82vh; */
  margin: 10px;
}

.testHeader {
  margin: 0;
}


.testCategory {
  border-radius: 50px;
  background-color: #f2ee8d;
  color: #1f1f1f;
  font-weight: 400;
  font-size: 14px;
  padding: 5px;
  display: inline-flex;
  align-items: baseline;
  min-width: 150px;
  justify-content: center;
}

.agriCultureColor {
  background-color: #f2ee8d;
}

.breedColor {
  background-color: #fbcfcf;
}

.detail_titl {
  min-width: 110px;
  color: #1f1f1f;
  font-weight: 600;
  font-size: 14px;
  display: inline-flex;
  align-items: baseline;
}
.detail_titl2 {
  min-width: 110px;
  color: #1f1f1f;
  font-weight: 600;
  font-size: 14px;
  align-items: baseline;
}
.detail_minf {
  font-size: 9px;
  min-width: 50px;
  align-items: baseline;
}

.detail_minf2 {
  font-size: 9px;
  min-width: 50px;
  align-items: baseline;
}

.detail_minf3 {
  font-size: 9px;
  min-width: 50px;
  align-items: baseline;
}

.detail_minf4 {
  font-size: 9px;
}

.testInfo {
  // margin: 9px;
  color: #000;
  font-weight: 700;
  background-color: #f6f6f6;
  display: grid;
}

.testInfoLabels {
  color: #000;
  padding: 8px 10px 8px 10px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;

}

.testInfoValues {
  align-self: end;
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
}

.testStatus {
  align-self: start;
  margin-left: 16px;
}

.testContent {
  overflow-y: scroll;

  .gifContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: calc(40vh - 60px);

    .loadingGif {
      width: 50px;
      height: 50px;
    }
  }
}

.startTestButton {
  border: none;
  border-radius: 24px;
  background-color: #49b2aa;
  align-self: center;
  width: 320px;
  max-width: 100%;
  color: #fff;
  text-align: center;
  cursor: pointer;
  // margin: 36px 0 -55px;
  // padding: 11px 70px;
  font: 400 15px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
}

.test_minf {
  font-size: 9px;
  font-weight: 600;
  // display: block;
}

.resultStatus {
  color: #cb3030;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  padding: 5px;

  .status_minf {
    font-size: 9px;
    font-weight: 600;
    display: block;
  }
}

.resultStatus2 {
  color: #1B2FE7;
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  letter-spacing: -0.434px;
  padding: 10px;

  .status_minf {
    font-size: 9px;
    font-weight: 600;
    display: block;
  }
}

.scoreDetails {
  color: #000;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  font: 400 14px Inter, sans-serif;
}

.congratsMessage {
  color: #1f1f1f;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.resultImg {
  width: 100%;
  height: 100%;
  // padding: 0px 10px 0px 10px;
}

@media (min-width: 992px) {
  .resultImg {
    max-width: 667px;
  }
  .resultImage {
    width: 100%;
    height: 412px;
    background-image: url("../../../../public/kousyu/kousyu_syuuryou_915_412.jpg");
    position: relative;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
  }
}

@media (max-width: 991px) {
  .resultImg {
    max-width: 440px;
  }
  .resultImage {
    width: 315px;
    height: 220px;
    background-image: url("../../../../public/kousyu/kousyu_syuuryou_812_375.jpg");
    position: relative;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
  }
}

.resultImage2 {
  aspect-ratio: 1.5;
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100px;
  max-width: 640px;
}

.actionButtons {
  display: flex;
  max-width: 100%;
  gap: 20px;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  justify-content: center;
  margin: 8px;
}

.actionButtons2 {
  display: flex;
  max-width: 100%;
  gap: 20px;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  justify-content: center;
  margin: 60px;
}

.actionImg {
  display: flex;
  max-width: 100%;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  justify-content: center;
}

.actionButtonsMobile {
  display: grid;
  max-width: 100%;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  justify-content: center;
  margin: 5px;
  padding-top: 15px;
}

.questionButton {
  cursor: pointer;
  border-radius: 50px;
  background-color: #49b2aa;
  margin: 8px;
  // height: 40px;
  width: 222px;
  max-width: 100%;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  padding: 8px 10px;
  border: none;
}

.questionButton2 {
  cursor: pointer;
  border-radius: 50px;
  margin: 8px;
  width: 222px;
  max-width: 100%;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding: 8px 10px;
  border: none;
}

.questionButton3 {
  cursor: pointer;
  border-radius: 50px;
  background-color: #49b2aa;
  margin: 10px;
  width: 261px;
  max-width: 100%;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  padding: 12px 20px;
  border: none;
}
@media (max-width: 375px) {
  .detail_title {
    font-size: 14px;
    margin: 5px 10px;
    width: 200px;
    display: flex;
    display: -webkit-flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 376px) and (max-width: 430px){
  .detail_title {
    font-size: 14px;
    margin: 5px 10px;
    width: 210px;
    display: flex;
    display: -webkit-flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }
}

@media (max-height: 667px) {
  .testCard {
    height: 80vh;
  }
  .startTestButton {
    margin-bottom: 25px;
    padding: 10px 20px;
  }
}

@media (min-height: 668px) {
  .testCard {
    height: 83vh;
  }
}

@media (min-width: 667px) {
  .testInstructions {
    color: #000;
    line-height: 30px;
    margin: 40px 20px 40px 20px;
    font-size: 14px;
  }

  .testbtndiv {
    display: flex;
    max-width: 100%;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    justify-content: center;
  }
}

@media (max-width: 666px) {
  .testInstructions {
    color: #000;
    line-height: 30px;
    margin: 30px 0 30px 0;
    font-size: 14px;
  }

  .testbtndiv {
    display: flex;
    max-width: 100%;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    justify-content: center;
  }
}

@media (min-height: 668px) {
  .startTestButton {
    margin: 45px;
    padding: 10px 20px;
  }
}
@media (max-width: 667px) {
  .actionButtonsMobile {
    display: grid;
    margin: 10px;
    padding-top: 10px;
  }
}
@media (min-width: 668px) and (max-width: 991px){
  .actionButtonsMobile {
    display: grid;
    max-width: 100%;
    gap: 20px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    justify-content: center;
    margin: 15px;
    padding-top: 35px;
  }
}
@media (max-width: 991px) {
  .questionButton {
    display: grid;
    padding: 10px 20px;
  }

  .questionButton2 {
    display: grid;
    padding: 10px 20px;
  }

  .questionButton3 {
    padding: 10px 20px;
  }

  .actionButtons {
    margin: 40px 0 10px;
  }

  .actionImg {
    margin: 5px;
  }

  .resultStatus {
    font-size: 28px;
  }

  .testContent {
    height: 70vh;
    padding: 0px 0px;
  }

  .testStatus {
    margin-left: 10px;
  }

  .testCategory {
    margin: 2px;
  }

  .testHeader {
    padding: 0px;
    margin: 0px;
  }

  .testCard {
    /* height: 85%; */
    padding: 8px;
  }

  .mainColumn {
    width: 100%;
  }

  .contentWrapper {
    max-width: 100%;
  }

  .background {
    max-width: 100%;
  }

  .twoColumnLayout {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }

  .mainContent {
    max-width: 100%;
  }
}

@media (max-width: 414px) {
  .testInfoLabels {
    padding: 5px;
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  .testContent {
    height: 70vh;
    overflow-y: scroll;
  }
  .detail_title {
    font-size: 12px;
    margin: 3px 10px;
    display: flex;
    display: -webkit-flex;
    overflow: hidden;
  }
  .testInfoLabels {
    padding: 5px;
  }
  .questionButton {
    height: 45px;
  }
}