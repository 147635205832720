@media (min-width: 992px) {
  .table {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;

    .table-title {
      padding: 30px 15px 30px 15px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .question-title {
        // text-wrap: nowrap;
        width: 85%;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .table-heading {
        display: inline-flex;
        align-items: baseline;
        font: 600 16px Inter, sans-serif;

        .minf {
          font-size: 9px;
        }
      }
    }

    .table-title2 {
      padding: 30px 15px 30px 15px;
      align-items: center;

      .question-title2 {
        text-overflow: ellipsis;
        overflow: hidden;
        font: 600 16px Inter, sans-serif;
      }

      .question-time {
        margin: 5px;
        width: 95%;
        text-align: end;
      }
    }

    .div-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      // margin-bottom: 10px;
    }

    .timer {
      width: 100vw;
    }

    .video-container {
      overflow-y: scroll;
      width: 100%;
      height: 85vh;
    }

    .table-container {
      background-color: white;
      margin: 10px;

      .user-info-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .user-info-title {
          color: #000;
          font: 600 14px Inter, sans-serif;
          margin: 15px 14px 14px 13px;
          padding: 10px 15px 10px 15px;

          .minf {
            font-size: 9px;
          }
        }

        .edit-button {
          background-color: #1f1f1f;
          color: #fff;
          border-radius: 50px;
          align-self: start;
          font-weight: 400;
          margin: 14px 15px 13px 13px;
          padding: 10px 33px;
          border: 1px solid #d9d9d9;

          .minf {
            font: 400 9px Inter, sans-serif;
          }
        }
      }

      .deactivate-button {
        border-radius: 50px;
        background-color: #fff;
        align-self: start;
        color: #1f1f1f;
        font-weight: 400;
        margin: 14px 0 0 13px;
        padding: 10px 33px;
        border: 1px solid #d9d9d9;

        .minf {
          font: 400 9px Inter, sans-serif;
        }
      }

      .submit {
        text-align: center;

        .submitButton {
          border-radius: 24px;
          background-color: rgba(73, 178, 170, 0.6);
          align-self: center;
          margin-top: 19px;
          width: 260px;
          font-size: 14px;
          color: #fff;
          padding: 5px 32px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }

        .submitButtonActive {
          border-radius: 24px;
          background-color: #49b2aa;
          align-self: center;
          margin-top: 19px;
          width: 260px;
          font-size: 14px;
          color: #fff;
          padding: 5px 32px;
          border: none;

          .minf {
            font: 400 9px Inter, sans-serif;
            display: block;
          }
        }
      }


      .pagination-container {
        // height: 100%;
        padding: 15px;
        overflow-y: scroll;

        .tableHeader {
          background-color: #f6f6f6;
          color: #1f1f1f;
          display: flex;
          font: 400 16px Inter, sans-serif;
          justify-content: space-between;
          padding: 10px;
          white-space: nowrap;
        }

        .data-table {
          border-collapse: collapse;
          background-color: #fff;

          .rowItem {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid #f6f6f6;

            .subject {
              display: inline-block;
              // width: 25%;
            }

            .title {
              width: 25%;
              text-wrap: wrap;
            }

            .status {
              width: 25%;
              text-wrap: wrap;
            }

            .subjectWrapper {
              width: 25%;

              .subject {
                border-radius: 50px;
                margin: 0px;
                padding: 3px 10px 4px 10px;
              }

              .agriculture {
                background-color: #f2ee8d;
                text-wrap: nowrap;
              }

              .livestock {
                background-color: #fbcfcf;
                text-wrap: nowrap;
              }
            }

          }

        }

        .pagination-buttons {
          text-align: end;
          padding: 15px;
          position: relative;
          background-color: #ffff;

          .page-btn {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: 0 5px;
            padding: 0;
          }

          .page-btn-active {
            background-color: #1e1e1e;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: 0 5px;
            padding: 0;
          }

        }

        .pagination-buttons button:disabled {
          cursor: not-allowed;
        }

        .pagination-buttons span {
          margin: 0 10px;
        }
      }
    }

    .searchBar {
      // position: relative;
      display: flex;
      gap: 100px;
      padding: 15px 15px 5px 15px;
    }

    .searchField {
      display: flex;
      align-items: center;
    }

    .statusFilter {
      display: flex;
      align-items: center;
    }

    .titleInput,
    .statusSelect {
      border: 1px solid #dadada;
      border-radius: 5px;
      font: 400 14px Inter, sans-serif;
      height: 35px;
      padding: 0 10px;
      width: 280px;
      // z-index: -1;
    }

    .filterLabel {
      margin-right: 10px;
      flex-wrap: nowrap;
      width: 80px;
      text-align: start;
    }

    .resetButton {
      align-items: center;
      background: none;
      border: none;
      color: #000;
      cursor: pointer;
      text-wrap: nowrap;
      font: 400 16px Inter, sans-serif;
    }

    .gif-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: calc(40vh - 60px);

      .loading-gif {
        width: 50px;
        height: 50px;
      }
    }
    .gif-container-video {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: calc(40vh - 60px);
      .loading-gif-video {
        width: 50px;
        height: 50px;
      }
    }


    .draggable {
      cursor: move !important;
      width: 15vw;
    }

    .draggable1 {
      cursor: pointer !important;
      width: 25%;

      .star1 {
        font-size: 14px;
      }
    }

    /* Button Styles */
    .button {
      padding: 8px 12px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      margin: 0 4px;
      transition: 0.5s;
    }

    /* Form Styles */
    .form-container {
      max-width: 400px;
      margin: auto;
      margin-bottom: 50px;
      padding: 20px;
    }

    .form-group {
      margin-bottom: 15px;
    }

    .form-group label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .form-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      outline: none;
      border-radius: 4px;
      font-size: 14px;
    }

    .form-container form button {
      float: right;
    }

    .input-error {
      border-color: #f44336;
      /* Red */
    }

    .error-message {
      color: #f44336;
      /* Red */
      font-size: 12px;
      margin: 5px 0 0;
    }

    .submit-button {
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background-color: #4caf50;
      /* Green */
      color: white;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .submit-button:hover {
      background-color: #45a049;
      /* Darker Green */
    }

    .action-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 991px) {
  .table {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;

    // .video-container {
    //   overflow-y: scroll;
    //   width: 100%;
    //   height: 79vh;
    // }

    .table-title {
      padding: 20px 15px 20px 15px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .question-title {
        width: 80%;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .table-heading {
        display: inline-flex;
        align-items: baseline;

        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .table-title2 {
      padding: 20px 15px 20px 15px;
      align-items: center;

      .question-title2 {
        text-overflow: ellipsis;
        overflow: hidden;
        font: 600 16px Inter, sans-serif;
      }

      .question-time {
        margin: 5px;
        width: 95%;
        text-align: end;
      }
    }
  }

  .div-line {
    background-color: #000;
    border-color: #e8f6f5;
    height: 1px;
    width: 100%;
    // margin-bottom: 10px;
  }

  .timer {
    width: 100vw;
  }

  .table-container {
    height: 78vh;
    background-color: white;
    margin: 8px;

    .gif-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: calc(40vh - 60px);

      .loading-gif {
        width: 50px;
        height: 50px;
      }
    }
    .gif-container-video {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: calc(40vh - 60px);
      .loading-gif-video {
        width: 50px;
        height: 50px;
      }
    }
    .user-info-header {
      display: flex;
      margin-top: 10px;
      justify-content: space-between;

      .user-info-title {
        color: #000;
        font: 600 14px Inter, sans-serif;
        margin: 5px;
        padding: 15px 2px 15px 2px;
        align-content: center;
        text-wrap: nowrap;

        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }

      .edit-button {
        background-color: #1f1f1f;
        color: #fff;
        border-radius: 50px;
        align-self: center;
        margin-top: 5px;

        .minf {
          font: 400 9px Inter, sans-serif;
          display: block;
          width: 60px;
          height: 15px;
        }
      }
    }

    .deactivate-button {
      border-radius: 50px;
      background-color: #fff;
      color: #1f1f1f;
      font-weight: 400;
      margin: 8px;
      padding: 5px 30px;
      border: 1px solid #d9d9d9;

      .minf {
        font: 400 9px Inter, sans-serif;
        display: block;
      }
    }

    .submit {
      text-align: center;

      .submitButton {
        border-radius: 24px;
        background-color: rgba(73, 178, 170, 0.6);
        align-self: center;
        margin-top: 19px;
        width: 260px;
        font-size: 14px;
        color: #fff;
        padding: 5px 32px;
        border: none;

        .minf {
          font: 400 9px Inter, sans-serif;
          display: block;
        }
      }

      .submitButtonActive {
        border-radius: 24px;
        background-color: #49b2aa;
        align-self: center;
        margin-top: 19px;
        width: 220px;
        font-size: 14px;
        color: #fff;
        padding: 5px 32px;
        border: none;

        .minf {
          font: 400 9px Inter, sans-serif;
          display: block;
        }
      }
    }

    .pagination-container {
      // height: 100%;
      padding: 15px;
      overflow-y: scroll;

      .tableHeader {
        background-color: #f6f6f6;
        color: #1f1f1f;
        display: flex;
        font: 400 16px Inter, sans-serif;
        justify-content: space-between;
        padding: 10px;
        white-space: nowrap;
      }

      .data-table {
        border-collapse: collapse;
        background-color: #fff;

        .rowItem {
          cursor: pointer;
          // display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          border-bottom: 1px solid #f6f6f6;

          .subject {
            display: inline-block;
            // width: 25%;
          }

          .title {
            width: 25%;
            text-wrap: wrap;
          }

          .status {
            width: 25%;
            text-wrap: wrap;
          }

          .subjectWrapper {
            width: 25%;

            .subject {
              border-radius: 50px;
              margin: 0px;
              padding: 3px 10px 4px 10px;
            }

            .agriculture {
              background-color: #f2ee8d;
              text-wrap: nowrap;
            }

            .livestock {
              background-color: #fbcfcf;
              text-wrap: nowrap;
            }
          }

        }

      }

      .pagination-buttons {
        text-align: end;
        padding: 15px;
        position: relative;
        background-color: #ffff;

        .page-btn {
          background-color: #49b2aa;
          border: none;
          border-radius: 50%;
          color: #fff;
          cursor: pointer;
          font: 400 14px Inter, sans-serif;
          height: 24px;
          width: 24px;
          margin: 0 5px;
          padding: 0;
        }

        .page-btn-active {
          background-color: #1e1e1e;
          border: none;
          border-radius: 50%;
          color: #fff;
          cursor: pointer;
          font: 400 14px Inter, sans-serif;
          height: 24px;
          width: 24px;
          margin: 0 5px;
          padding: 0;
        }

      }

      .pagination-buttons button:disabled {
        cursor: not-allowed;
      }

      .pagination-buttons span {
        margin: 0 10px;
      }
    }
  }

  .searchBar {
    // position: relative;
    display: flex;
    gap: 100px;
    padding: 15px 15px 5px 15px;
  }

  .searchField {
    display: flex;
    align-items: center;
  }

  .statusFilter {
    display: flex;
    align-items: center;
  }

  .titleInput,
  .statusSelect {
    border: 1px solid #dadada;
    border-radius: 5px;
    font: 400 14px Inter, sans-serif;
    height: 35px;
    padding: 0 10px;
    width: 280px;
    // z-index: -1;
  }

  .filterLabel {
    margin-right: 10px;
    flex-wrap: nowrap;
    width: 80px;
    text-align: start;
  }

  .resetButton {
    align-items: center;
    background: none;
    border: none;
    color: #000;
    cursor: pointer;
    text-wrap: nowrap;
    font: 400 16px Inter, sans-serif;
  }

  .gif-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-top: calc(40vh - 60px);
    height: 74vh;

    .loading-gif {
      width: 50px;
      height: 50px;
    }
  }

  .gif-container-video {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: calc(40vh - 60px);
    .loading-gif-video {
      width: 50px;
      height: 50px;
    }
  }

  .draggable {
    cursor: move !important;
    width: 15vw;
  }

  .draggable1 {
    cursor: pointer !important;
    width: 25%;

    .star1 {
      font-size: 14px;
    }
  }

  /* Button Styles */
  .button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin: 0 4px;
    transition: 0.5s;
  }

  /* Form Styles */
  .form-container {
    max-width: 400px;
    margin: auto;
    margin-bottom: 50px;
    padding: 20px;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .form-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    outline: none;
    border-radius: 4px;
    font-size: 14px;
  }

  .form-container form button {
    float: right;
  }

  .input-error {
    border-color: #f44336;
    /* Red */
  }

  .error-message {
    color: #f44336;
    /* Red */
    font-size: 12px;
    margin: 5px 0 0;
  }

  .submit-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #4caf50;
    /* Green */
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .submit-button:hover {
    background-color: #45a049;
    /* Darker Green */
  }

  .action-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media (min-width: 360px) and (max-height: 740px) {
  .video-container {
    // margin-right: 10px;
    overflow-y: scroll;
    width: 100%;
    height: 79vh;
  }
}

@media (min-width: 375px) and (max-height: 812px) {
  .video-container {
    // margin-right: 10px;
    overflow-y: scroll;
    width: 100%;
    height: 83vh;
  }
}

@media (min-width: 390px) and (max-height: 932px) {
  .video-container {
    // margin-right: 10px;
    overflow-y: scroll;
    width: 100%;
    height: 83vh;
  }
}

@media (min-width: 430px) and (max-height: 932px) {
  .video-container {
    // margin-right: 10px;
    overflow-y: scroll;
    width: 100%;
    height: 83vh;
  }
}

@media (max-width: 430px) and (max-height: 667px) {
  .video-container {
    // margin-right: 10px;
    overflow-y: scroll;
    width: 100%;
    height: 80vh;
  }
}

@media (min-width: 768px) and (max-height: 1180px) {
  .video-container {
    // margin-right: 10px;
    overflow-y: scroll;
    width: 100%;
    height: 82vh;
  }
}

@media (min-width: 768px) and (max-height: 1180px) {
  .video-container {
    // margin-right: 10px;
    overflow-y: scroll;
    width: 100%;
    height: 84vh;
  }
}