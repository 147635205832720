  .sign-in-page {
    // background-color: #1A2847;
    background: linear-gradient(180deg, #e8f6f5 0%, #8fcaef 100%);
    text-align: center;
    // flex-direction: column;
    min-height: 100vh;
    width: 100vw;

    .div {
      // flex-direction: column;
      padding: 10px;
      justify-content: center;
      display: grid;
      height: 100vh;
      .splash-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        // background-color: #1A2847;

        .splash-image {
          width: 125px;
          height: 75px;
        }
        .loading-gif {
          margin-top: 16px;
          width: 50px;
          height: 50px;
        }

      }

      .div-2 {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 20px;
        
        text-align: center;
        padding: 0 14px 0;

        .div-5 {
          color: #fff;
          letter-spacing: -0.72px;
          align-self: center;
          gap: 0px;
          margin: 10px 0 10px 0px;
          font: 700 24px 'Montserrat';
          .logow {
            width: 129px;
            height: 75px;
          }
        }
      }

      .div-6 {
        border-radius: 8px;
        background-color: #fff;
        align-self: center;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 34px 20px 22px;

        .msg {
          align-self: center;
          display: flex;
          margin-top: 11px;
          gap: 4px;
          font-size: 12px;
          color: #ef0000;
          justify-content: center;
          .img-msg {
            aspect-ratio: 1;
            object-fit: auto;
            object-position: center;
            width: 24px;
          }
          .div-msg {
            font-family: 'Montserrat', Noto Sans Japanese;
            margin: auto 0;
          }
        }

        .div-7 {
          color: #000000;
          text-align: center;
          font-family: 'Montserrat';
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.579px;
        }

        .div-90 {
          display: flex;
          margin-top: 38px;
          margin-bottom: 30px;
          align-items: center;
          gap: 10px;
          font-weight: 500;
          text-align: center;
          letter-spacing: -0.58px;
          .div-91 {
            border-color: rgba(24, 63, 147, 0.6);
            // border-style: solid;
            // border-width: 1px;
            background-color: rgba(24, 63, 147, 0.6);
            align-self: stretch;
            width: 127px;
            height: 1px;
            gap: 0px;
            margin: auto 0;
            width: 50%;
          }

          .div-92 {
            font-family: 'Montserrat';
            align-self: stretch;
            color: #000000;
          }
        }

        .div-8 {
          display: flex;
          flex-direction: column;
          gap: 7px;
          .div-9 {
            align-self: start;
            color: #000000;
            
            gap: 0px;
            font: 550 14px 'Montserrat';
          }

          .div-10 {
            border-radius: 5px;
            border-color: rgba(24, 63, 147, 1);
            border-style: solid;
            border-width: 1px;
            color: #000000;
            
            height: 39px;
            text-align: start;
            font: 550 14px 'Montserrat';
            outline: black;
          }
          input[type=text],
          input[type=email],
          input[type=password] {
            padding: 8px;
          }

          .invalid {
            border: 1px solid red;
          }
        }

        .but-8 {
          border-radius: 24px;
          // border-color: rgba(23, 62, 146, 1);
          border-style: solid;
          border-width: 1px;
          background-color: #1A2847;
          align-self: center;
          color: #fff;
          justify-content: center;
          padding: 8px 25px;
          font: 15px 'Montserrat';
          width: 80%;
          margin-left: 10%;

          p {
            cursor: pointer;
          }
        }

        .sign-in-btn {
          border-radius: 24px;
          border: 1px solid #173E92;
          border-width: revert;
          min-height: 40px;
          width: 80%;
          background-color: #ffffff;
        }

        .div-18 {
          color: #000000;
          text-align: center;
          font-family: 'Montserrat';
          font-weight: 500;
          letter-spacing: -0.58px;
          padding-top: 10px;
          text-decoration-line: none;
          align-self: center;
          gap: 0px;
          text-wrap: wrap;
        }
      }

      .divv-6 {
        border-radius: 8px;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 1px 20px 22px;

        .msg {
          align-self: center;
          display: flex;
          margin-top: 11px;
          gap: 4px;
          font-size: 12px;
          color: #ef0000;
          justify-content: center;

          .img-msg {
            aspect-ratio: 1;
            object-fit: auto;
            object-position: center;
            width: 24px;
          }

          .div-msg {
            font-family: 'Montserrat', Noto Sans Japanese;
            margin: auto 0;
          }
        }

        .div-7 {
          color: #FFFFFF;
          text-align: center;
          font-family: 'Montserrat';
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.579px;
        }

        .div-90 {
          display: flex;
          margin-top: 25px;
          margin-bottom: 20px;
          align-items: center;
          gap: 10px;
          font-weight: 500;
          text-align: center;
          letter-spacing: -0.58px;

          .div-91 {
            background-color: #FFFFFF;
            height: 1px;
            width: 48%;
          }

          .div-92 {
            font-family: "Montserrat";
            align-self: stretch;
            width: 20%;
          }
        }

        .div-8 {
          display: flex;
          flex-direction: column;
          gap: 7px;

          .div-9 {
            align-self: start;
            color: #FFFFFF;
            
            gap: 0px;
            font: 550 14px 'Montserrat';
          }

          .div-10 {
            border-radius: 5px;
            border-style: solid;
            background-color: #1A2847;
            // border-color: #FFFFFF;
            border-width: 1px;
            color: #FFFFFF;
            
            height: 39px;
            text-align: start;
            font: 550 14px 'Montserrat';
          }

          input::placeholder {
            color: #fff;
          }

          input[type=text] {
            padding: 8px;
          }
          input[type=email] {
            padding: 8px;
          }
          input[type=password] {
            padding: 8px;
          }

          .invalid {
            border-color: red;
          }
        }

        .but-8 {
          border-radius: 24px;
          border-style: solid;
          border-width: 1px;
          background-color: #FFFFFF;
          color: #212121;
          height: 40px;
          padding: 8px 25px;
          font: 15px 'Montserrat';
          width: 80%;
          margin-left: 10%;
          p {
            cursor: pointer;
          }
        }

        .sign-in-btn {
          border-radius: 24px;
          color: #FFFFFF;
          min-height: 40px;
          width: 80%;
          border-style: solid;
          background-color: #1A2847;
          border-color: #FFFFFF;
          margin-top: 15px;
          margin-bottom: 10px;
        }

        .div-18 {
          // color: rgba(24, 63, 147, 0.6);
          text-align: center;
          font-family: 'Montserrat';
          font-weight: 500;
          letter-spacing: -0.58px;
          padding-top: 10px;
          text-decoration-line: none;
          align-self: center;
          gap: 0px;
          text-wrap: wrap;
        }
      }
    }

    //
  }

  @media (min-width: 770px) {
    .sign-in-page {
      width: 100%;
      text-align: -webkit-center;
      .div {
        width: 500px;
        .div-2 {
          padding-top: 60px;
        }
        .divv-6 {
          width: 100%;
          .but-8 {
            margin-left: 0%;
        }
        }
      }
    }
  }