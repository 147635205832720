.topbar {
    background-color: #fff;
    width: 100%;
    align-content: center;
    border-bottom: 1px solid rgb(208, 208, 208);

    .topbarContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3px;
        margin: 3px;

        .leftTopbar {
            display: flex;
            align-items: center;

            a:link {
                text-decoration: none;
            }

            .title-icon {
                cursor: pointer;
                width: 28px;
            }
        }

        .rightTopbar {
            display: flex;
            align-items: center;

            .rightIconContainer {
                position: relative;
                margin-left: 20px;
            }
        }

    }
}



.menuIcon {
    cursor: pointer;
}

.menuIcon:hover {
    cursor: pointer;
}


.badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #ff0000;
    color: white;
    font-size: 12px;
    /* padding: 3px 5px; */
    border-radius: 50%;
}

@media (min-width: 992px) {
    .topbar {
        height: 100px;
        align-content: center;
    }

    .logo {
        cursor: pointer;
        color: #000;
        text-align: center;
        align-self: center;
        font: 22px Inter, sans-serif;
        margin: 10px;
        zoom: 0.4;
    }
}

@media (max-width: 991px) {
    .logo {
        cursor: pointer;
        color: #000;
        text-align: center;
        align-self: center;
        font: 22px Inter, sans-serif;
        margin: 12px 10px 10px 10px;
        width: 60%;
    }
}

@media (max-width: 667px) {
    .logo {
        cursor: pointer;
        color: #000;
        text-align: center;
        align-self: center;
        font: 22px Inter, sans-serif;
        width: 85%;
    }
}

@media (max-width: 320px) {
    .topbar {
        align-content: center;
    }
}