.tearms {
  margin-top: 60px;
  background-color: rgb(255, 255, 255);
  //   height: 50vh;
  position: absolute;

  .bg {
    z-index: 105;
    inset: 0 0 0 0;
    background-color: rgba(0, 0, 0, 0.776);
    position: fixed;
    cursor: pointer;
  }

  .container {
    z-index: 1120;
    position: fixed;
    background-color: #ffffff;
    color: #1d2226;
    border-radius: 20px;
    top: 50vh;
    left: 50vw;
    width: 99%;
    height: 99%;
    transform: translate(-50%, -50%);
    padding: 20px;

    .x-title {
      display: flex;
      justify-content: space-between;
      color: #000000;
      cursor: pointer;

      .title-icon {
        cursor: pointer;
        width: 24px;
      }
    }

    .title {
      display: flex;
      justify-content: center;
      padding-bottom: 5px;
      height: 7%;
      color: #000000;

      .minf {
        font-size: 9px;
      }
    }

    .txt {
      display: flex;
      justify-content: space-between;
      height: 5%;
      color: #000000;

      span {
        cursor: pointer;
      }
    }

    .form-container {
      margin: 5px 0 0 0;
      overflow: auto;
      width: 100%;
      height: 80%;
      color: #000000;
      font-size: 14px;

      .form-title {
        text-align: start;
        font-size: 14px;
      }

      .form-txt {
        text-align: start;
        font-size: 12px;
        margin: 8px;
      }

      .form-txt1 {
        text-align: start;
        font-size: 12px;
        margin-top: 8px;
      }


      .pg-rules-listnumber {
        list-style-type: decimal;
        margin-left: -10px;
        font-size: 12px;

        li {
          text-align: start;

          .pg-rules-listnumber {
            // list-style-type: disclosure-open;
            margin-left: -10px;

            li {
              text-align: start;

              .pg-rules-listnumber {
                list-style-type: disc;
                margin-left: -10px;
              }
            }
          }
        }
      }
    }
  }

  .container-paving {
    display: flex;
    z-index: 1000;
    position: fixed;
    background-color: #ffffff;
    color: #1d2226;
    border-radius: 10px;
    top: 50%;
    left: 50vw;
    max-width: 450px;
    min-height: 40vh;
    align-content: center;
    align-items: center;
    justify-content: center;
    -webkit-align-content: center;
    transform: translate(-50%, -50%);

    .title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 5px;
      height: 10%;
      color: rgba(23, 62, 146, 1);

      span {
        cursor: pointer;
      }
    }

    .txt {
      display: flex;
      justify-content: space-between;
      padding-top: 5px;
      height: 10%;
      color: rgba(23, 62, 146, 1);

      span {
        cursor: pointer;
      }
    }

    .form-paving {
      overflow: auto;
      width: 100%;
      // min-height: 320px;
      // align-content: center;

      .txt {
        display: flow;
        font-size: 12px;
        color: rgba(24, 63, 147, 0.6);
        text-align: center;
        font-family: 'Montserrat', Noto Sans Japanese;
        font-weight: 500;
        text-wrap: wrap;
        text-decoration-line: none;
        align-self: center;
        height: 150px;
        padding-top: 50px;

        p {
          cursor: pointer;
        }
      }

      .div-7 {
        border-radius: 8px;
        border-width: 1px;
        background-color: #fff;
        align-self: center;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px 5px 15px 5px;

        .div-8 {
          text-align: start;
          margin: 6px;

          .minf {
            font: 400 9px Inter, sans-serif;
          }

          .titl1 {
            text-align: center;
            font-size: 14px;
            margin-bottom: 15px;
          }

          .titl2 {
            margin: 5px;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            // margin-bottom: 20px;
          }

          .minf1 {
            font-size: 9px;
            text-align: center;
            font-weight: 400;
          }

          p {
            font: 400 14px Inter, sans-serif;
            text-align: center;
            margin: 5px;
            width: 100%;
          }
        }

        .statusSelect {
          border: 1px solid #dadada;
          border-radius: 5px;
          height: 40px;
          padding: 5px;
          width: 261px;
          font: 400 14px Inter, sans-serif;
          margin-bottom: 10px;
        }

        .filterLabel {
          margin: 0px 0px 5px 0px;
          flex-wrap: nowrap;
          align-items: baseline;
          width: 261px;
          font-size: 14px;
          display: flex;

          .minf {
            font-size: 9px;
          }
        }

        .filterLabel1 {
          display: flex;

          .titl {
            text-align: center;
            margin-bottom: -18px;
            margin-right: 15px;
          }

          .minf {
            font-size: 9px;
            text-align: center;
            margin-right: 15px;
          }
        }

        .div-91 {
          cursor: pointer;
          text-align: center;
          align-content: center;
          display: flex;
          margin: 25px;
          border-radius: 24px;
          color: #fff;
          background-color: #49B2AA;
          border-style: none;
          border-width: 1px;
          height: 40px;
          width: 210px;
          align-items: center;
          justify-content: center;
          .minf {
            font: 400 9px Inter, sans-serif;
          }

          .loadingGif {
            width: 28px;
          }
        }

        .div-92 {
          cursor: pointer;
          border-radius: 50px;
          background-color: #fff;
          align-content: center;
          color: #1f1f1f;
          font-weight: 400;
          border: 1px solid #d9d9d9;
          height: 40px;
          width: 210px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 5px;

          .minf {
            font: 400 9px Inter, sans-serif;
            margin-top: 6px;
          }
        }
      }
    }

  }

  .container-paving1 {
    display: flex;
    z-index: 1000;
    position: fixed;
    background-color: #ffffff;
    color: #1d2226;
    border-radius: 10px;
    top: 50vh;
    left: 50vw;
    min-width: 340px;
    max-width: 450px;
    min-height: 30vh;
    align-content: center;
    align-items: center;
    justify-content: center;
    -webkit-align-content: center;
    transform: translate(-50%, -50%);

    .title1 {
      display: flex;
      justify-content: space-between;
      padding-bottom: 5px;
      height: 10%;
      color: rgba(23, 62, 146, 1);

      span1 {
        cursor: pointer;
      }
    }

    .txt1 {
      display: flex;
      justify-content: space-between;
      padding-top: 5px;
      height: 10%;
      color: rgba(23, 62, 146, 1);

      span1 {
        cursor: pointer;
      }
    }

    .form-paving1 {
      margin: 10px;
      overflow: auto;

      .txt1 {
        display: flow;
        font-size: 12px;
        color: rgba(24, 63, 147, 0.6);
        text-align: center;
        font-family: 'Montserrat', Noto Sans Japanese;
        font-weight: 500;
        text-wrap: wrap;
        text-decoration-line: none;
        align-self: center;
        height: 150px;
        padding-top: 50px;

        p {
          cursor: pointer;
        }
      }

      .div-71 {
        border-radius: 8px;
        border-width: 1px;
        background-color: #fff;
        align-self: center;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px 5px 15px 5px;

        .div-81 {
          text-align: start;
          margin: 6px;

          .minf {
            font: 400 9px Inter, sans-serif;
          }

          .titl1 {
            text-align: center;
            font-size: 14px;
            margin-bottom: 15px;
          }

          .titl2 {
            margin: 5px;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            // margin-bottom: 20px;
          }

          .minf1 {
            font-size: 9px;
            text-align: center;
            font-weight: 400;
          }

          p {
            letter-spacing: 0.19px;
            font: 400 16px Inter, sans-serif;
            text-align: center;
            margin: 10px;
            // text-overflow: ellipsis;
            white-space: nowrap;
            width: 222px;
            overflow: hidden;
          }
        }

        .div-91 {
          cursor: pointer;
          text-align: center;
          align-content: center;
          text-decoration-line: none;
          margin: 25px;
          border-radius: 24px;
          color: #fff;
          background-color: #49B2AA;
          border-style: none;
          border-width: 1px;
          height: 40px;
          width: 210px;

          .minf {
            font: 400 9px Inter, sans-serif;
          }

          .loadingGif {
            width: 28px;
          }
        }

        .div-92 {
          cursor: pointer;
          border-radius: 50px;
          background-color: #fff;
          align-content: center;
          color: #1f1f1f;
          font-weight: 400;
          border: 1px solid #d9d9d9;
          height: 40px;
          width: 210px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          .minf {
            font: 400 9px Inter, sans-serif;
            margin-top: 6px;
          }
        }
      }
    }

  }
}

@media (max-width: 320px) {
  .container-paving {
    min-width: 300px;
  }
}

@media (min-width: 321px) and (max-width: 991px) {
  .container-paving {
    min-width: 350px;
  }

  .div-7 {
    .div-8 {
      .titl1 {
        padding-top: 15px;
      }

    }
  }
}

@media (min-width: 992px) {
  .container-paving {
    min-width: 360px;
  }

  .div-7 {
    min-width: 440px;

    .statusSelect {
      margin-bottom: 40px;
    }

    .div-8 {
      .titl1 {
        text-align: center;
        font-size: 14px;
        padding-top: 20px;

        .minf {
          font: 400 9px Inter, sans-serif;
        }
      }

      .titl2 {
        text-align: center;
        font-size: 20px;
        font-weight: 700;
      }

      .minf1 {
        font-size: 9px;
        font-weight: 400;
        text-align: center;
      }
    }
  }
}