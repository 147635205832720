.lectureVideo {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.background {
  background-color: #e8f6f5;
  display: flex;
  width: 100%;
  flex-direction: column;
}


.serviceLogo {
  background-color: #fff;
  width: 100%;
  color: #000;
  text-align: center;
  // padding: 28px 39px;
  font: 400 12px Inter, sans-serif;
}


.contentWrapper {
  gap: 20px;
  display: flex;
}

.activeItem {
  border-radius: 50px 0 0 50px;
  background-color: #1e1e1e;
  align-self: stretch;
  display: flex;
  height: 47px;
}

.sidebarItem {
  margin: 31px 0 0 30px;
}

.sidebarItem:first-child {
  margin-top: -32px;
}



.mainColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  /* margin-left: 5px; */
  background-color: #e8f6f5;
}


.mainColumnContent {
  background-color: #e8f6f5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100vh;
}


.sectionTitle {
  color: #000;
  align-self: start;
  margin-left: 10px;
  font: 700 20px Inter, sans-serif;
}

.divider {
  background-color: #1f1f1f;
  display: flex;
  margin: 10px;
  height: 1px;
}

.videoContainer {
  background-color: #e8f6f5;
  display: flex;
  margin: 10px;
  flex-direction: column;
  height: 100vh;
}


.videoWrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  padding: 10px;
}


.videoContent {
  background-color: #fff;
  display: flex;
  /* height: 100vh; */
  margin: 10px;
}

.videoPlayerColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  margin: 10px;
}

.videoThumbnail {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.playButton {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 125px;
  align-self: center;
  max-width: 100%;
}

.progressBar {
  border-radius: 50px;
  background-color: #fff;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 2px 3px;
  border: 1px solid #dadada;
}


.progressIndicator {
  border-radius: 50px;
  background-color: #cb3030;
  display: flex;
  width: 161px;
  height: 6px;
}

.controlsWrapper {
  display: flex;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}



.leftControls {
  display: flex;
  gap: 8px;
}

.controlIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
}

.rewindIcon {
  width: 22px;
  align-self: start;
  margin-top: 9px;
}

.playIcon {
  width: 24px;
  margin: auto 0;
}

.forwardIcon {
  width: 35px;
}

.volumeIcon {
  width: 27px;
  margin: auto 0;
}

.rightControls {
  display: flex;
  align-items: start;
  gap: 19px;
  margin: auto 0;
}

.settingsIcon,
.fullscreenIcon {
  width: 24px;
}

.notesColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  height: 100vh;
  margin: 5px;
}

.notesWrapper {
  margin: 2px;
  padding: 3px;
  gap: 5px;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  color: #000;
  font: 14px / 24px Inter, sans-serif;
}


.addNoteButton {
  display: flex;
  gap: 5px;
  font-weight: 400;
  border: 1px solid #dadada;
  width: 180px;
  height: 40px;
  cursor: pointer;
}

.addNoteIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: center;
  margin-left: 5px;
}

.noteCard {
  border-radius: 10px;
  background-color: #f6f6f6;
  display: flex;
  margin-top: 13px;
  flex-direction: column;
  /* color: #cb3030; */
}



.noteDate {
  font-weight: 700;
  align-self: start;
}

.noteContent {
  font-weight: 400;
  margin-top: 8px;
  padding-bottom: 10px;
}

.highlightedNote {
  border-radius: 10px;
  background-color: #f6f6f6;
  display: flex;
  // margin: 4px;px
  flex-direction: column;
  align-items: start;
  font-weight: 700;

  /* color: blueviolet; */
  .memo {
    height: 100px;
    width: 100%;
    overflow-y: scroll;
    font-weight: 400;
    flex-wrap: wrap;
    font-family: Inter, sans-serif;
  }

  .saveBtn {
    background-color: #49b2aa;
    border: none;
    border-radius: 5%;
    color: #fff;
    cursor: pointer;
    font: 400 14px Inter, sans-serif;
    height: 24px;
    width: 60px;
    margin: 5px;
    align-self: end;
    text-align: center;
  }
}


.noteTimestamp {
  color: #1b2fe7;
  margin-top: 4px;
}



.videoTitle {
  display: flex;
  display: -webkit-flex;
  font-size: 15px;
  font-weight: 550;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  .minf {
    font-size: 10px;
    align-items: baseline;
    display: contents;
  }
}

.statusBadge {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #1f1f1f;
  justify-content: center;
}

.statusText {
  border-radius: 20px;
  background-color: #9fe1dd;
  align-self: center;
  min-width: 180px;
  height: 40px;

  .titl {
    text-align: center;
    font-size: 14px;
    margin-top: 3px;
  }

  .minf {
    font-size: 9px;
    text-align: center;
  }
}


.unstar {
  cursor: pointer;
  font-size: 26px;
  width: 10%;
  text-align: center;
}

.star {
  cursor: pointer;
  font: 600 26px Inter, sans-serif;
  width: 10%;
  text-align: center;
  color: #F2EE8D;
}

.preparationTextIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  height: 35px;
  align-self: center;
  margin-left: 5px;
}

.videoicon {
  cursor: pointer;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  height: 24px;
  align-self: center;
  // margin-right: 5px;
}

.preparationTextLabel {
  display: flex;
  height: 40px;
  align-items: center;
  cursor: pointer;
  font-size: 14px;

  .minf {
    font-size: 9px;
    align-self: baseline;
    display: contents;
  }
}

.lectureDescription {
  /* border-radius: 10px; */
  background-color: #f6f6f6;
  color: #000;
  margin-top: 10px;
  min-height: 100px;
  font: 400 14px/24px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}



@media (min-width: 992px) {
  .videoInfoWrapper {
    display: flex;
    margin-top: 10px;
    gap: 10px;
    font-family: Inter, sans-serif;
    justify-content: space-between;
  }

  .videoTitle {
    display: flex;
    display: -webkit-flex;
    -webkit-flex: 1;
    flex: 1;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: nowrap;
    font-size: 16px;
    font-weight: 600;
    .minf {
      font-size: 10px;
      align-items: baseline;
      display: contents;
    }
  }

  .videoStatusWrapper {
    display: flex;
    font-weight: 400;
    align-items: center;
    flex-flow: column-reverse;
  }

  .statusBadge {
    flex-direction: row;
    font-size: 14px;
    color: #1f1f1f;
    text-align: center;
    align-items: center;
    padding: 1px 10px 10px 10px;
  }

  .preparationTextButton {
    border: 1px solid #dadada;
    background-color: #fff;
    display: flex;
  }
}

@media (max-width: 991px) {
  .videoInfoWrapper {
    display: flex;
    margin-top: 10px;
    gap: 10px;
    font-family: Inter, sans-serif;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .videoStatusWrapper {
    display: flex;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
  }

  .statusBadge {
    width: 125px;
    height: 35px;
    justify-content: center;
    display: contents;
  }

  .preparationTextButton {
    background-color: #fff;
    display: flex;
    gap: 10px;
    color: #000;
    margin-right: 5px;
    height: 40px;
    font: 400 14px Inter, sans-serif;
    border: 1px solid #dadada;
    cursor: pointer;
  }

  .videoContainer {
    max-width: 100%;
  }

  .videoWrapper {
    max-width: 100%;
    /* padding-left: 20px; */
  }

  .videoContent {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }

  .progressBar {
    max-width: 100%;
    margin: 6px 0 6px 0;
  }

  .controlsWrapper {
    max-width: 100%;
  }

  /* .notesWrapper {
    height: 100vh;
  } */

  .notesColumn {
    width: 100%;
    height: 50vh;
  }

  .noteCard {
    padding-right: 10px;
  }

  .highlightedNote {
    padding-right: 10px;
  }

  .lectureDescription {
    max-width: 100%;
    overflow-wrap: anywhere;
  }

  .preparationTextLabel {
    display: flex;
    height: 40px;
    align-items: center;
    .minf {
      font-size: 9px;
      align-self: baseline;
      display: contents;
    }
  }

  .statusText {
    min-width: 130px;

    .titl {
      text-align: center;
    }

    .minf {
      font-size: 9px;
      text-align: center;
    }
  }
}