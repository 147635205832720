.contentWrapper {
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 100%;
  height: 100vh;
  /* max-width: 100%; */
  flex-direction: column;
  align-items: center;
  padding: 74px 40px;
}


.background {
  background-color: #e8f6f5;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 190px 80px;
}

.container {
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  margin-bottom: -41px;
  width: 580px;
  max-width: 100%;
  /* height: 90vh; */
  flex-direction: column;
  align-items: center;
  padding: 73px 80px 31px;
}

.content {
  display: flex;
  margin: 10px;
  flex-direction: column;
}

.logo {
  color: #000;
  text-align: center;
  align-self: center;
  font: 22px Inter, sans-serif;
  margin: 20px;
}

.label {
  font-size: 14px;
  align-self: start;
  margin-top: 40px;
}

.input {
  border-radius: 5px;
  margin-top: 10px;
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid #1f1f1f;
  width: 100%;
  height: 40px;
}

.emailDisplay {
  border-radius: 5px;
  margin-top: 9px;
  font-size: 14px;
  white-space: nowrap;

  padding: 7px 16px;
  border: 1px solid #1f1f1f;
}

.instructions1 {
  font-size: 12px;
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
}

.instructions3 {
  font-size: 12px;
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: start;
}

.instructions {
  display: inline-flex;
  align-items: baseline;
  text-wrap: wrap;
  flex-wrap: wrap;
  min-width: 400px;
}

.inst {
  font-size: 12px;
  text-wrap: nowrap;
}

.instructionsDiv {
  margin: 28px 4px 28px 4px;
}

.submitButton {
  border-radius: 30px;
  background-color: rgba(73, 178, 170, 0.6);
  align-self: center;
  margin: 20px;
  max-width: 100%;
  font-size: 14px;
  color: #fff;
  width: 90%;
  border: none;
}

.submitButtonActive {
  border-radius: 24px;
  background-color: #49b2aa;
  align-self: center;
  margin: 20px;
  width: 261px;
  max-width: 100%;
  font-size: 14px;
  color: #fff;
  width: 90%;
  height: 45px;
  padding: 2px 20px;
  border: none;
}

.mini {
  font-size: 8px;
  text-wrap: nowrap;
}

.serviceLogo {
  color: #000;
  text-align: center;
  align-self: center;
  font: 22px Inter, sans-serif;
  margin: 10px 10px 20px 10px;
  height: 100%;
  width: 100%;
}

.serviceLogom {
  color: #000;
  text-align: center;
  align-self: center;
  font: 22px Inter, sans-serif;
  margin: 30px 10px 30px 10px;
  width: 100%;
}

@media (max-width: 991px) {
  .background {
    max-width: 100%;
    padding: 100px 20px;
  }

  .submitButton {
    white-space: initial;
    padding: 5px;
  }

  .instructions {
    text-wrap: wrap;
    justify-content: flex-start;
    align-items: baseline;
  }

  .instructionsDiv {
    margin: 30px 5px 30px 5px;
  }

  .emailDisplay {
    padding-right: 20px;
    white-space: initial;
  }

  .label {
    margin-top: 40px;
  }

  .container {
    margin-bottom: 10px;
    padding: 50px 20px;
  }
}

@media (max-width: 400px) {
  .background {
    max-width: 100%;
    padding: 100px 20px;
  }

  .submitButton {
    white-space: initial;
    padding: 5px;
  }

  .instructions {
    font-size: 12px;
    text-wrap: wrap;
    width: 100%;
    min-width: 270px;
    justify-content: flex-start;
    align-items: baseline;
  }

  .instructions1 {
    flex-wrap: wrap;
  }

  .instructions3 {
    flex-wrap: wrap;
  }

  .emailDisplay {
    padding-right: 20px;
    white-space: initial;
  }

  .label {
    margin-top: 40px;
  }

  .container {
    margin-bottom: 10px;
    padding: 50px 20px;
  }
}