.background {
  background-color: #e8f6f5;
  display: flex;
  width: 100%;
  flex-direction: column;
}

@media (max-width: 991px) {
  .background {
    max-width: 100%;
  }

  .mainContent {
    /* max-width: 100%; */
    text-wrap: wrap;
    // height: 84vh;
    overflow-y: scroll;
  }
}

.contentWrapper {
  gap: 20px;
  display: flex;
}

@media (max-width: 991px) {
  .contentWrapper {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
}

.activeItem {
  border-radius: 50px 0 0 50px;
  background-color: #1e1e1e;
  align-self: stretch;
  display: flex;
  margin-top: -132px;
  height: 47px;
}

.questionContent {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 5px;
}

@media (max-width: 991px) {
  .questionContent {
    /* height: 85vh; */
  }
}

.questionHeader {
  display: flex;
  margin-top: 98px;
  flex-direction: column;
  font-family: Inter, sans-serif;
  color: #000;
}

@media (max-width: 991px) {
  .questionHeader {
    max-width: 100%;
    margin-top: 40px;
  }
}

.questionTitle {
  font-size: 20px;
  font-weight: 700;
  align-self: start;
}

.divider {
  background-color: #1f1f1f;
  display: flex;
  margin-top: 19px;
  width: 1170px;
  max-width: 100%;
  height: 1px;
}

.questionForm {
  background-color: #f6f6f6;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 24px;
}

@media (max-width: 991px) {
  .questionForm {
    max-width: 100%;
    padding: 0 20px;
  }
}

.questionContainer {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 5px;
}

.questionContainer1 {
  background-color: #f6f6f6;
  text-align: center;
  margin: 5px;
  padding: 5px;
  height: 80vh;
  align-content: center;
}
.questionContainer2 {
  background-color: #f6f6f6;
  text-align: center;
  align-content: center;
  justify-items: center;
  height: 80vh;
  margin: 10px;
  padding: 10px;
  padding-bottom: calc(60vh - 40px);
  .resTitle {
    cursor: pointer;
    text-align: center;
    padding: 10px 9px 10px 9px;
    margin: 10px 6px 10px 6px;
    font: 14px Montserrat, sans-serif;
    .minf1 {
      font-size: 9px;
    }
  }
  .submitButton {
    cursor: pointer;
    border-radius: 24px;
    background-color: #49b2aa;
    position: relative;
    width: 261px;
    max-width: 100%;
    color: #fff;
    text-align: center;
    border: none;
    padding: 5px;
    margin: 10px;
    font: 12px Montserrat, sans-serif;
    P {
      align-items: center;
    }
    .minf1 {
      font-size: 9px;
    }
  }
}
.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.questionText {
  display: flex;
  // flex-wrap: wrap;
}

.optionsContainer {
  display: flex;
  margin: 5px;
  width: 100%;
  align-items: center;
}

@media (max-width: 991px) {
  .optionsContainer {
    display: contents;
    margin: 5px;
  }
}

.optionItem {
  display: flex;
  gap: 11px;
  flex: 1;
  font-size: 14px;
  align-items: center;
  .optext {
    margin: 5px;
  }
  .surtext {
    height: 90px;
    width: 100%;
    overflow-y: scroll;
    font-weight: 400;
    flex-wrap: wrap;
    border: 1px solid #231815;
    font-family: Inter, sans-serif;
    margin: 0 10px;
  }
}

.optionItemText {
  display: flow;
  gap: 11px;
  flex: 1;
  font-size: 14px;
  align-items: center;
  .optext1 {
    display: flex;
    margin: 0 0 0px 10px;
    justify-content: flex-start;
    color: black;
  }
  .surtext {
    color: black;
    height: 90px;
    width: 98%;
    overflow-y: scroll;
    font-weight: 400;
    flex-wrap: wrap;
    border: 1px solid #231815;
    font-family: Inter, sans-serif;
    margin: 0 10px;
  }
}

.radioButton {
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  width: 12px;
  height: 12px;
  margin: auto 0;
  border: 1px solid #1f1f1f;
}

.textInputContainer {
  background-color: #f6f6f6;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  line-height: 32px;
  padding: 29px 36px;
}

@media (max-width: 991px) {
  .textInputContainer {
    max-width: 100%;
    /* padding: 0 20px; */
  }
}

.textInput {
  background-color: #fff;
  display: flex;
  margin-top: 8px;
  height: 30px;
  border: 1px solid #dadada;
}

@media (max-width: 991px) {
  .textInput {
    max-width: 100%;
  }
}

.divButton {
  display: flex;
  .nextButton {
    cursor: pointer;
    border-radius: 24px;
    background-color: #49b2aa;
    align-self: end;
    position: relative;
    color: #fff;
    text-align: center;
    height: 40px;
    border: none;
    padding: 10px;
    margin: 10px;
    font: 14px Montserrat, sans-serif;
  }

  .preButton {
    cursor: pointer;
    border-radius: 24px;
    background-color: #49b2aa;
    align-self: end;
    position: relative;
    color: #fff;
    text-align: center;
    height: 40px;
    border: none;
    padding: 10px;
    margin: 10px;
    font: 14px Montserrat, sans-serif;
  }

  .submitButton {
    cursor: pointer;
    border-radius: 24px;
    background-color: #49b2aa;
    align-self: center;
    position: relative;
    width: 220px;
    max-width: 100%;
    color: #fff;
    text-align: center;
    height: 40px;
    border: none;
    padding: 10px;
    margin: 10px;
    font: 14px Montserrat, sans-serif;
    .minf1 {
      font-size: 9px;
    }
  }
}
.divButton1 {
  justify-content: end;
}
.divButton2 {
  justify-content: space-between;
}
.divButton3 {
  justify-content: center;
}

@media (max-width: 991px) {
  .submitButton {
    .minf1 {
      font-size: 9px;
    }
  }
}


@media (min-width: 992px) {
  .mainContent {
    height: 82vh;
    overflow-y: scroll;
  }
}


@media (max-width: 991px) {
  .questionContainer {
    line-height: 20px;
  }

  .questionNumber {
    margin: 2px;
    font-size: 14px;
    // align-content: center;
    .qtitl {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
    }
    .qtitl2 {
      width: 30px;
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
    }
    .qminf {
      font-size: 9px;
      width: 50px;
    }
    .qminf1 {
      font-size: 9px;
      width: 101px;
      text-align: center;
    }
  }
}

@media (min-width: 992px) {
  .questionNumber {
    margin: 3px;
    font-size: 14px;
    // align-content: center;

    .qtitl {
      width: 110px;
      display: inline-flex;
      align-items: baseline;
    }
    .qtitl2 {
      width: 30px;
      display: inline-flex;
      align-items: baseline;
    }
    .qtitl1 {
      width: 50px;
      display: inline-flex;
      align-items: baseline;
    }
    .qminf {
      font-size: 9px;
      width: 50px;
    }
    .qminf1 {
      font-size: 9px;
      width: 101px;
      text-align: center;
    }
  }
}