@media (min-width: 992px) {
  .tests {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 80%;

    .tests-title {
      padding: 30px 15px 30px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .tests-heading {
        display: inline-flex;
        align-items: baseline;
        font: 600 16px Inter, sans-serif;

        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .tests-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }

    .tests-container {
      width: 100%;

      .tests-search {
        // position: relative;
        display: flex;
        gap: 100px;
        padding: 5px 15px 5px 5px;

        .tests-searchField {
          display: flex;
          align-items: center;

          .tests-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 280px;
            // z-index: -1;
          }

          .tests-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: center;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }
          }
        }

        .tests-statusFilter {
          display: flex;
          align-items: center;

          .tests-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 280px;
            // z-index: -1;
          }

          .tests-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
            font-size: 14px;
          }
        }
      }

      .tests-pagination {
        // height: 72vh;
        padding: 15px;
        overflow-y: scroll;

        .tests-pagination-tableHeader {
          background-color: #f6f6f6;
          color: #1f1f1f;
          display: flex;
          font: 400 16px Inter, sans-serif;
          justify-content: space-evenly;
          padding: 10px;
          white-space: nowrap;

          .draggabletest1 {
            cursor: pointer !important;
            width: 10%;
            display: inline-flex;
            justify-content: center;
            align-items: baseline;
            font-size: 14px;

            .test_minf {
              font-size: 9px;
              font-weight: normal;
            }

            .star1 {
              font-size: 14px;
            }
          }

          .draggabletest2 {
            cursor: pointer !important;
            width: 70%;
            display: inline-flex;
            align-items: baseline;
            font-size: 14px;
            justify-content: center;

            .test_minf {
              font-size: 9px;
              font-weight: normal;
            }

            .star1 {
              font-size: 14px;
            }
          }
        }
      }

      .tests-pagination-data {
        border-collapse: collapse;
        background-color: #fff;
        padding-bottom: 10px;

        .rowItem {
          cursor: pointer;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          padding: 10px 6px 10px 6px;
          border-bottom: 1px solid #f6f6f6;

          .subject {
            display: inline-block;
          }

          .title {
            width: 70%;
            display: flex;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex-wrap: nowrap;
            text-wrap: nowrap;
            overflow: hidden;
            font-size: 14px;

            .minf {
              font-size: 10px;
              align-content: end;
            }
          }

          .status {
            width: 10%;
            justify-items: center;
            font-size: 14px;

            .test_titl {
              text-align: center;
              margin-bottom: -15px;
            }

            .test_minf {
              font-size: 9px;
              font-weight: normal;
              text-align: center;
            }
          }

          .subjectWrapper {
            width: 10%;
            align-items: center;
            justify-content: center;
            display: inline-flex;
            font-size: 14px;

            .subject {
              border-radius: 50px;
              padding: 4px;
            }

            .agriculture {
              background-color: #f2ee8d;
              text-wrap: nowrap;
              display: inline-flex;
              align-items: baseline;
              padding: 4px 20px 4px 20px;
            }

            .test_minf {
              font-size: 9px;
              font-weight: normal;
              // margin-left: 5px;
            }

            .minf1 {
              margin-left: 15px;
            }

            .livestock {
              background-color: #fbcfcf;
              text-wrap: nowrap;
              display: inline-flex;
              align-items: baseline;
              padding: 4px 20px 4px 20px;
            }
          }

        }

      }

      .tests-pagination-buttons {
        text-align: end;
        padding: 10px 20px 15px 15px;
        // position: relative;
        background-color: #ffff;

        .pageButton1 {
          background-color: #49b2aa;
          border: none;
          border-radius: 50%;
          color: #fff;
          cursor: pointer;
          font: 400 16px Inter, sans-serif;
          height: 24px;
          width: 25px;
          margin: -5px 5px -7px 5px;
        }

        .pageButton2 {
          background-color: #49b2aa;
          border: none;
          border-radius: 50%;
          color: #fff;
          cursor: pointer;
          font: 400 16px Inter, sans-serif;
          height: 24px;
          width: 25px;
          margin: 0px 0px -7px 5px;
          ;
        }

        .page-btn {
          background-color: #49b2aa;
          border: none;
          border-radius: 50%;
          color: #fff;
          cursor: pointer;
          font: 400 14px Inter, sans-serif;
          height: 24px;
          width: 24px;
          margin: -5px 5px -5px 5px;
          padding: 0;
        }

        .page-btn-active {
          background-color: #fff;
          border: none;
          border-radius: 50%;
          color: #1e1e1e;
          cursor: pointer;
          font: 400 14px Inter, sans-serif;
          height: 24px;
          width: 24px;
          margin: -5px 5px -5px 5px;
          padding: 0;
        }

      }

      .tests-pagination-buttons button:disabled {
        cursor: not-allowed;
      }

      .tests-pagination-buttons span {
        margin: 0 10px;
      }
    }

    .gif-container {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-top: calc(40vh - 60px);
      height: 75vh;

      .loading-gif {
        width: 50px;
        height: 50px;
      }
    }
  }
}

@media (max-width: 991px) {
  .tests {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;

    .tests-title {
      padding: 20px 15px 20px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .tests-heading {
        display: inline-flex;
        align-items: baseline;
        font: 600 16px Inter, sans-serif;

        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .tests-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }

    .tests-container {
      width: 100%;

      .tests-search {
        margin: 0 10px 0 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 7px;
        overflow-y: visible;

        .tests-searchField {
          gap: 5px;
          display: flex;
          align-items: center;

          .tests-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 280px;
            // z-index: -1;
          }

          .tests-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: center;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }
          }
        }

        .tests-statusFilter {
          gap: 5px;
          display: flex;
          align-items: center;

          .tests-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 280px;
            // z-index: -1;
          }

          .tests-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
            font-size: 14px;
          }
        }
      }

      .tests-pagination {
        // height: 75vh;
        padding: 10px;
        // overflow-y: scroll;

        .tests-pagination-tableHeader {
          background-color: #f6f6f6;
          color: #1f1f1f;
          display: flex;
          font: 400 16px Inter, sans-serif;
          justify-content: space-evenly;
          padding: 7px 10px;
          white-space: nowrap;

          .draggabletest1 {
            cursor: pointer !important;
            display: inline-flex;
            align-items: baseline;
            font-size: 14px;

            .test_minf {
              font-size: 9px;
              font-weight: normal;
            }

            .star1 {
              font-size: 14px;
            }
          }

          .draggabletest2 {
            cursor: pointer !important;
            width: 60%;
            display: inline-flex;
            align-items: baseline;
            font-size: 14px;
            justify-content: center;

            .test_minf {
              font-size: 9px;
              font-weight: normal;
            }

            .star1 {
              font-size: 14px;
            }
          }
        }

        .tests-pagination-data {
          border-collapse: collapse;
          background-color: #fff;
          // height: 59vh;
          overflow-y: scroll;
          // padding-bottom: 10px;

          .rowItem {
            cursor: pointer;
            padding: 3px;
            margin: 10px 3px 10px 3px;
            border-bottom: 1px solid #f6f6f6;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            gap: 3px;

            .subject {
              display: inline-block;
              // width: 25%;
            }

            .title {
              width: 60%;
              display: flex;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex-wrap: nowrap;
              text-wrap: nowrap;
              overflow: hidden;
              margin: 5px;
              font-size: 14px;
              align-items: baseline;
              .minf {
                font-size: 9px;
              }
            }

            .status {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              justify-items: center;
              font-size: 14px;

              .test_titl {
                width: 75px;
                text-align: center;
                margin-bottom: -15px;
              }

              .test_minf {
                font-size: 9px;
                width: 75px;
                font-weight: normal;
                text-align: center;
              }
            }

            .subjectWrapper {
              display: flex;
              align-items: center;
              font-size: 14px;
              white-space: nowrap;
              flex-wrap: nowrap;
              text-wrap: nowrap;

              .subject {
                border-radius: 50px;
                margin: 0px;
                padding: 4px;
              }

              .agriculture {
                background-color: #f2ee8d;
                text-wrap: nowrap;
                flex-wrap: nowrap;
                display: flex;
                align-items: baseline;
              }

              .test_minf {
                font-size: 9px;
                font-weight: normal;
                // margin-left: 5px;
              }

              .minf1 {
                margin-left: 10px;
              }

              .livestock {
                background-color: #fbcfcf;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
              }
            }

          }

        }

        .tests-pagination-buttons {
          .pageButton1 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: -5px 5px -7px 5px;
          }

          .pageButton2 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: 0px 0px -7px 5px;
            ;
          }

          .page-btn {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

          .page-btn-active {
            background-color: #fff;
            border: none;
            border-radius: 50%;
            color: #1e1e1e;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

        }

        .tests-pagination-buttons button:disabled {
          cursor: not-allowed;
        }

        .tests-pagination-buttons span {
          margin: 0 10px;
        }
      }

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);
        height: 75vh;

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }
    }

  }
}

@media (max-width: 320px) {
  .tests {
    .tests-title {
      padding: 20px 15px 20px 15px;
    }

    .tests-container {
      width: 100%;

      .tests-search {
        margin: 0 10px 0 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow-y: visible;

        .tests-searchField {
          display: flex;
          align-items: center;

          .tests-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 215px;
            // z-index: -1;
          }

          .tests-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: center;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }
          }
        }

        .tests-statusFilter {
          display: flex;
          align-items: center;

          .tests-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 215px;
            // z-index: -1;
          }

          .tests-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
            font-size: 14px;
          }
        }
      }

      .tests-pagination {
        .tests-pagination-data {
          height: 52vh;
        }
      }
    }
  }
}

@media (min-width: 321px) and (max-width: 375px) {
  .tests {
    .tests-title {
      padding: 20px 15px 20px 15px;
    }

    .tests-container {
      width: 100%;

      .tests-search {
        margin: 0 10px 0 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow-y: visible;

        .tests-searchField {
          display: flex;
          align-items: center;

          .tests-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 260px;
            // z-index: -1;
          }

          .tests-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: center;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }
          }
        }

        .tests-statusFilter {
          display: flex;
          align-items: center;

          .tests-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 260px;
            // z-index: -1;
          }

          .tests-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
            font-size: 14px;
          }
        }
      }

      .tests-pagination {
        .tests-pagination-data {
          height: 54vh;
        }
      }
    }
  }
}

@media (max-width: 375px) and (min-height: 740px) {
  .tests {
    .tests-title {
      padding: 20px 15px 20px 15px;
    }

    .tests-container {
      width: 100%;

      .tests-search {
        margin: 0 10px 0 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow-y: visible;

        .tests-searchField {
          display: flex;
          align-items: center;

          .tests-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 250px;
            // z-index: -1;
          }

          .tests-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: center;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }
          }
        }

        .tests-statusFilter {
          display: flex;
          align-items: center;

          .tests-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 250px;
            // z-index: -1;
          }

          .tests-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
            font-size: 14px;
          }
        }
      }

      .tests-pagination {
        .tests-pagination-data {
          height: 62vh;
        }
      }
    }
  }
}

@media (min-width: 390px) and (max-width: 413px) {
  .tests {
    .tests-container {
      .tests-pagination {
        .tests-pagination-data {
          height: 62vh;
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: 414px) and (max-width: 666px) {
  .tests {
    .tests-container {
      .tests-pagination {
        .tests-pagination-data {
          height: 65vh;
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: 414px) and (min-height: 740px) {
  .tests {
    .tests-container {
      .tests-pagination {
        .tests-pagination-data {
          overflow-y: scroll;
          // height: 65vh;
          // padding-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: 667px) and (max-width: 767px) {
  .tests {
    .tests-container {
      .tests-pagination {
        .tests-pagination-data {
          height: 64vh;
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 819px) {
  .tests {
    .tests-container {
      .tests-search {
        .tests-searchField {
          .tests-titleInput {
            width: 220px;
          }
        }

        .tests-statusFilter {
          .tests-statusSelect {
            width: 220px;
          }
        }
      }

      .tests-pagination {
        .tests-pagination-data {
          height: 72vh;
        }
      }
    }


  }
}

@media (min-width: 820px) and (max-width: 991px) {
  .tests {
    .tests-container {
      .tests-search {
        .tests-searchField {
          .tests-titleInput {
            width: 220px;
          }
        }

        .tests-statusFilter {
          .tests-statusSelect {
            width: 220px;
          }
        }
      }

      .tests-pagination {
        .tests-pagination-data {
          height: 73vh;
        }
      }
    }


  }
}

@media (min-height: 1366px) {
  .tests {

    .tests-container {
      width: 100%;

      .tests-search {
        // position: relative;
        width: 75vw;
        display: flex;
        gap: 100px;
        padding: 15px 15px 5px 15px;

        .tests-searchField {
          display: flex;
          align-items: center;

          .tests-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 250px;
            // z-index: -1;
          }

          .tests-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: center;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }
          }
        }

        .tests-statusFilter {
          display: flex;
          align-items: center;

          .tests-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 220px;
            // z-index: -1;
          }

          .tests-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
            font-size: 14px;
          }
        }
      }

      .tests-pagination {
        // height: 100%;
        padding: 15px;
        overflow-y: scroll;

        .tests-pagination-tableHeader {
          background-color: #f6f6f6;
          color: #1f1f1f;
          display: flex;
          font: 400 16px Inter, sans-serif;
          justify-content: space-evenly;
          padding: 10px;
          white-space: nowrap;

          .draggabletest1 {
            cursor: pointer !important;
            width: 25%;
            display: inline-flex;
            align-items: baseline;
            font-size: 14px;

            .test_minf {
              font-size: 9px;
              font-weight: normal;
            }

            .star1 {
              font-size: 14px;
            }
          }

          .draggabletest2 {
            cursor: pointer !important;
            width: 50%;
            display: inline-flex;
            align-items: baseline;
            font-size: 14px;

            .test_minf {
              font-size: 9px;
              font-weight: normal;
            }

            .star1 {
              font-size: 14px;
            }
          }
        }

        .tests-pagination-data {
          border-collapse: collapse;
          background-color: #fff;
          width: 74vw;
          height: 50vh;
          padding-bottom: 10px;

          .rowItem {
            cursor: pointer;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid #f6f6f6;

            .subject {
              display: inline-block;
              // width: 25%;
            }

            .title {
              display: flex;
              width: 50%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;

              .minf {
                font-size: 10px;
                align-content: end;
              }
            }

            .status {
              width: 25%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              font-size: 14px;

              // text-align: center;
              // display: inline-flex;
              .test_titl {
                width: 75px;
                text-align: center;
                margin-bottom: -15px;
              }

              .test_minf {
                font-size: 9px;
                width: 75px;
                font-weight: normal;
                text-align: center;
              }
            }

            .subjectWrapper {
              width: 25%;
              display: inline-flex;
              font-size: 14px;

              .subject {
                border-radius: 50px;
                margin: 0px;
                padding: 4px 10px 4px 10px;
              }

              .agriculture {
                background-color: #f2ee8d;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
              }

              .minf {
                font-size: 9px;
                // margin-left: 5px;
              }

              .minf1 {
                margin-left: 10px;
                display: flex;
                text-overflow: ellipsis;
                flex: none;
                align-items: baseline;

                .minf {
                  font-size: 9px;
                  align-content: end;
                }
              }

              .livestock {
                background-color: #fbcfcf;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
              }
            }

          }

        }

        .tests-pagination-buttons {
          text-align: end;
          padding: 15px;
          // position: relative;
          background-color: #ffff;
          // width: 74vw;

          .pageButton1 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: -5px 5px -7px 5px;
          }

          .pageButton2 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: 0px 0px -7px 5px;
            ;
          }

          .page-btn {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

          .page-btn-active {
            background-color: #fff;
            border: none;
            border-radius: 50%;
            color: #1e1e1e;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

        }

        .tests-pagination-buttons button:disabled {
          cursor: not-allowed;
        }

        .tests-pagination-buttons span {
          margin: 0 10px;
        }
      }

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);
        height: 75vh;

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }
    }

  }
}

@media (min-width: 431px) and (max-width: 991px) {
  .tests-pagination-buttons {
    text-align: end;
    align-content: center;
    padding: 25px 40px 15px 15px;
    background-color: #ffff;
  }
}

@media (max-width: 430px) {
  .tests-pagination-buttons {
    text-align: end;
    padding: 10px 15px 15px 10px;
    background-color: #ffff;
  }
}