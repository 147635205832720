.mtearms {
  background-color: rgb(255, 255, 255);
  position: absolute;

  .mbg {
    background-color: rgba(0, 0, 0, 0.776);
    position: absolute;
    cursor: pointer;
  }

  .mcontainer {
    z-index: 1000;
    color: #1d2226;
    position: fixed;
    background-color: #49b2aa;
    top: 50vh;
    left: 50vw;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 10px;
    transform: translate(-50%, -50%);

    .mtitle {
      display: flex;
      justify-content: space-between;
      color: #000000;
      cursor: pointer;
      margin: 5px 10px 5px 10px;
      padding: 5px;

      .minf {
        font-size: 9px;
      }

      .micon {
        cursor: pointer;
        width: 24px;
      }
    }

    .menuContainer {
      position: relative;
      border-radius: 10px;
      background-color: #fff;
      padding: 15px;
      margin: 15px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
      flex-direction: column;
      height: 85%;
      border-right: 1px solid rgb(208, 208, 208);
      font: 400 16px Inter, sans-serif;

      .menuItem {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 15px;
        font-size: 18px;

        .minf {
          font-size: 9px;
        }
      }

      .menuDivider {
        margin: 5px;
        border: 1px dashed #dadada;
      }

      .menuIcon {
        aspect-ratio: 0.91;
        object-fit: contain;
        object-position: center;
        width: 10px;
        margin: auto 0;
      }
    }

  }
}

@media (max-width: 720px) {
  .mcontainer {
    padding: 10px;
  }
}

@media (min-width: 721px) {
  .mcontainer {
    padding: 40px 0px 15px 15px;
  }
}