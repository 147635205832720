@media (min-width: 992px) {
  .lecture {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;

    .lecture-title {
      padding: 30px 15px 30px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .lecture-heading {
        display: inline-flex;
        align-items: baseline;
        font: 600 16px Inter, sans-serif;

        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .lecture-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }

    .lecture-container {
      width: 100%;

      .lecture-search {
        // position: relative;
        display: flex;
        gap: 100px;
        padding: 5px 15px 5px 5px;

        .lecture-searchField {
          display: flex;
          align-items: center;

          .lecture-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 280px;
            // z-index: -1;
          }

          .lecture-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: center;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }
          }
        }

        .lecture-statusFilter {
          display: flex;
          align-items: center;

          .lecture-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 280px;
            // z-index: -1;
          }

          .lecture-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
            font-size: 14px;
          }
        }
      }

      .lecture-pagination {
        // height: 72vh;
        padding: 15px;
        overflow-y: scroll;

        .lecture-pagination-tableHeader {
          background-color: #f6f6f6;
          color: #1f1f1f;
          display: flex;
          font: 400 16px Inter, sans-serif;
          justify-content: space-evenly;
          padding: 10px;
          white-space: nowrap;

          .draggable1 {
            cursor: pointer !important;
            width: 10%;
            display: flex;
            align-items: baseline;
            justify-content: center;

            .minf {
              font-size: 9px;
            }

            .star1 {
              font-size: 14px;
            }
          }

          .draggable2 {
            cursor: pointer !important;
            width: 45%;
            display: flex;
            align-items: baseline;
            justify-content: center;

            .minf {
              font-size: 9px;
            }

            .star1 {
              font-size: 14px;
            }
          }

          .draggable3 {
            cursor: pointer !important;
            width: 30%;
            display: flex;
            align-items: baseline;
            justify-content: center;

            .minf {
              font-size: 9px;
            }

            .star1 {
              font-size: 14px;
            }
          }
        }

        .lecture-pagination-data {
          border-collapse: collapse;
          background-color: #fff;
          padding-bottom: 10px;

          .rowItem {
            cursor: pointer;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding: 8px 6px 8px 6px;
            border-bottom: 1px solid #f6f6f6;

            .subject {
              display: inline-block;
            }

            .title {
              display: flex;
              display: -webkit-flex;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 45%;
              align-items: baseline;
              .minf {
                font-size: 10px;
              }
            }

            .status {
              width: 10%;
              justify-items: center;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              font-size: 14px;

              .titl {
                text-align: center;
                margin-bottom: -18px;
              }

              .minf {
                font-size: 9px;
                text-align: center;
              }
            }

            .progressWrapper {
              display: flex;
              align-items: center;
              width: 30%;

              input[type="range" i] {
                background-color: #49b2aa;
                border-radius: 50px;
                height: 100%;
              }

              .progressBar {
                background-color: #fff;
                border: 1px solid #dadada;
                border-radius: 50px;
                height: 12px;
                width: 100%;
                margin-right: 8px;
              }

              .progressFill {
                background-color: #49b2aa;
                border-radius: 50px;
                height: 160%;
                font: 400 14px Inter, sans-serif;
                text-align: right;
              }

              .unstar {
                font-size: 26px;
                width: 10%;
                text-align: center;
                // color: #EFEFEF;
              }

              .star {
                font: 600 26px Inter, sans-serif;
                width: 10%;
                text-align: center;
                color: #F2EE8D;
              }
            }

            .subjectWrapper {
              font-size: 14px;
              width: 10%;
              justify-content: center;
              padding: 2px 5px 2px 5px;
              display: inline-flex;

              .subject {
                border-radius: 50px;
                margin: 0px;
                padding: 4px 10px 4px 10px;
              }

              .agriculture {
                background-color: #f2ee8d;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
                padding: 4px 20px 4px 20px;
              }

              .minf {
                font-size: 9px;
              }

              .minf1 {
                margin-left: 10px;
                display: flex;
                text-overflow: ellipsis;
                flex: none;
                align-items: baseline;

                .minf {
                  font-size: 9px;
                  align-content: end;
                }
              }

              .livestock {
                background-color: #fbcfcf;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
                padding: 4px 20px 4px 20px;
              }
            }

          }

        }

        .lecture-pagination-buttons {
          text-align: end;
          padding: 10px 20px 15px 15px;
          // position: relative;
          background-color: #ffff;

          .pageButton1 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: -5px 5px -7px 5px;
          }

          .pageButton2 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: 0px 0px -7px 5px;
            ;
          }

          .page-btn {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

          .page-btn-active {
            background-color: #fff;
            border: none;
            border-radius: 50%;
            color: #1e1e1e;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

        }

        .lecture-pagination-buttons button:disabled {
          cursor: not-allowed;
        }

        .lecture-pagination-buttons span {
          margin: 0 10px;
        }
      }

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);
        height: 75vh;

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .lecture {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;

    .lecture-title {
      padding: 20px 15px 20px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .lecture-heading {
        display: inline-flex;
        align-items: baseline;
        font: 600 16px Inter, sans-serif;

        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .lecture-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }

    .lecture-container {
      width: 100%;

      .lecture-search {
        margin: 0 10px 0 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 7px;
        overflow-y: visible;

        .lecture-searchField {
          display: flex;
          align-items: center;

          .lecture-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 280px;
            // z-index: -1;
          }

          .lecture-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: center;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }
          }
        }

        .lecture-statusFilter {
          display: flex;
          align-items: center;

          .lecture-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 280px;
            // z-index: -1;
          }

          .lecture-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
            font-size: 14px;
          }
        }
      }

      .lecture-pagination {
        // height: 75vh;
        padding: 10px;
        // overflow-y: scroll;

        .lecture-pagination-tableHeader {
          background-color: #f6f6f6;
          color: #1f1f1f;
          display: flex;
          font: 400 16px Inter, sans-serif;
          justify-content: space-evenly;
          padding: 7px 10px;
          white-space: nowrap;
        }

        .lecture-pagination-data {
          border-collapse: collapse;
          background-color: #fff;
          // height: 59vh;
          overflow-y: scroll;
          // padding-bottom: 10px;

          .rowItem {
            cursor: pointer;
            padding: 4px;
            margin: 4px;
            border-bottom: 1px solid #f6f6f6;

            .subject {
              display: inline-block;
              // width: 25%;
            }

            .title {
              // width: 25%;
              display: flex;
              display: -webkit-flex;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;

              .minf {
                font-size: 10px;
                align-content: end;
              }
            }

            .status {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 20%;
              justify-items: center;
              font-size: 14px;

              .titl {
                width: 75px;
                text-align: center;
                margin-bottom: -18px;
              }

              .minf {
                font-size: 9px;
                width: 75px;
                text-align: center;
              }
            }

            .progressWrapper {
              display: flex;
              align-items: center;
              gap: 5px;
              margin: 5px 0px 5px 0px;

              input[type="range" i] {
                background-color: #49b2aa;
                border-radius: 50px;
                height: 100%;
              }

              .progressBar {
                background-color: #fff;
                border: 1px solid #dadada;
                border-radius: 50px;
                height: 13px;
                width: 80%;
              }

              .progressFill {
                background-color: #49b2aa;
                border-radius: 50px;
                height: 160%;
                font: 400 14px Inter, sans-serif;
                text-align: right;
              }

              .unstar {
                font-size: 26px;
                width: 10%;
                text-align: center;
                // color: #EFEFEF;
              }

              .star {
                font: 600 26px Inter, sans-serif;
                width: 10%;
                text-align: center;
                color: #F2EE8D;
              }
            }

            .subjectWrapper {
              gap: 8px;
              display: flex;
              display: -webkit-flex;
              align-items: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 14px;

              .subject {
                border-radius: 50px;
                margin: 0px;
                padding: 4px 10px 4px 10px;
              }

              .agriculture {
                background-color: #f2ee8d;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
              }

              .minf {
                font-size: 9px;
                // margin-left: 5px;
              }

              .minf1 {
                width: 70%;
                display: flex;
                display: -webkit-flex;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                align-items: baseline;
                .minf {
                  font-size: 9px;
                }
              }

              .livestock {
                background-color: #fbcfcf;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
              }
            }

          }

        }

        .lecture-pagination-buttons {
          .pageButton1 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: -5px 5px -7px 5px;
          }

          .pageButton2 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: 0px 0px -7px 5px;
            ;
          }

          .page-btn {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

          .page-btn-active {
            background-color: #fff;
            border: none;
            border-radius: 50%;
            color: #1e1e1e;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

        }

        .lecture-pagination-buttons button:disabled {
          cursor: not-allowed;
        }

        .lecture-pagination-buttons span {
          margin: 0 10px;
        }
      }

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);
        height: 75vh;

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }
    }

  }
}

@media (max-width: 320px) {
  .lecture {
    .lecture-title {
      padding: 20px 15px 20px 15px;
    }

    .lecture-container {
      width: 100%;

      .lecture-search {
        margin: 0 10px 0 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow-y: visible;

        .lecture-searchField {
          display: flex;
          align-items: center;

          .lecture-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 215px;
            // z-index: -1;
          }

          .lecture-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: center;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }
          }
        }

        .lecture-statusFilter {
          display: flex;
          align-items: center;

          .lecture-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 215px;
            // z-index: -1;
          }

          .lecture-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
            font-size: 14px;
          }
        }
      }

      .lecture-pagination {
        .lecture-pagination-data {
          height: 52vh;
        }
      }
    }
  }
}

@media (min-width: 321px) and (max-width: 375px) {
  .lecture {
    .lecture-title {
      padding: 20px 15px 20px 15px;
    }

    .lecture-container {
      width: 100%;

      .lecture-search {
        margin: 0 10px 0 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow-y: visible;

        .lecture-searchField {
          display: flex;
          align-items: center;

          .lecture-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 260px;
            // z-index: -1;
          }

          .lecture-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: center;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }
          }
        }

        .lecture-statusFilter {
          display: flex;
          align-items: center;

          .lecture-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 260px;
            // z-index: -1;
          }

          .lecture-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
            font-size: 14px;
          }
        }
      }

      .lecture-pagination {
        .lecture-pagination-data {
          height: 54vh;
        }
      }
    }
  }
}

@media (max-width: 375px) and (min-height: 740px) {
  .lecture {
    .lecture-title {
      padding: 20px 15px 20px 15px;
    }

    .lecture-container {
      width: 100%;

      .lecture-search {
        margin: 0 10px 0 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow-y: visible;

        .lecture-searchField {
          display: flex;
          align-items: center;

          .lecture-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 250px;
            // z-index: -1;
          }

          .lecture-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: center;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }
          }
        }

        .lecture-statusFilter {
          display: flex;
          align-items: center;

          .lecture-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 250px;
            // z-index: -1;
          }

          .lecture-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
            font-size: 14px;
          }
        }
      }

      .lecture-pagination {
        .lecture-pagination-data {
          height: 62vh;
        }
      }
    }
  }
}

@media (min-width: 390px) and (max-width: 413px) {
  .lecture {
    .lecture-container {
      .lecture-pagination {
        .lecture-pagination-data {
          height: 62vh;
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: 414px) and (max-width: 666px) {
  .lecture {
    .lecture-container {
      .lecture-pagination {
        .lecture-pagination-data {
          height: 65vh;
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: 414px) and (min-height: 740px) {
  .lecture {
    .lecture-container {
      .lecture-pagination {
        .lecture-pagination-data {
          // height: 65vh;
          // padding-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: 667px) and (max-width: 767px) {
  .lecture {
    .lecture-container {
      .lecture-pagination {
        .lecture-pagination-data {
          height: 64vh;
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 819px) {
  .lecture {
    .lecture-container {
      .lecture-search {
        .lecture-searchField {
          .lecture-titleInput {
            width: 220px;
          }
        }

        .lecture-statusFilter {
          .lecture-statusSelect {
            width: 220px;
          }
        }
      }

      .lecture-pagination {
        .lecture-pagination-data {
          height: 72vh;

          .rowItem {
            .progressWrapper {
              // gap: 30px;

              .progressBar {
                width: 80%;
              }
            }
          }

        }
      }
    }


  }
}

@media (min-width: 820px) and (max-width: 991px) {
  .lecture {
    .lecture-container {
      .lecture-search {
        .lecture-searchField {
          .lecture-titleInput {
            width: 220px;
          }
        }

        .lecture-statusFilter {
          .lecture-statusSelect {
            width: 220px;
          }
        }
      }

      .lecture-pagination {
        .lecture-pagination-data {
          height: 73vh;

          .rowItem {
            .progressWrapper {
              // gap: 30px;

              .progressBar {
                width: 80%;
              }
            }
          }

        }
      }
    }


  }
}

@media (min-height: 1366px) {
  .lecture {

    .lecture-container {
      width: 100%;

      .lecture-search {
        // position: relative;
        width: 75vw;
        display: flex;
        gap: 100px;
        padding: 15px 15px 5px 15px;

        .lecture-searchField {
          display: flex;
          align-items: center;

          .lecture-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 250px;
            // z-index: -1;
          }

          .lecture-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: center;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }
          }
        }

        .lecture-statusFilter {
          display: flex;
          align-items: center;

          .lecture-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 220px;
            // z-index: -1;
          }

          .lecture-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
            font-size: 14px;
          }
        }
      }

      .lecture-pagination {
        // height: 100%;
        padding: 15px;
        overflow-y: scroll;

        .lecture-pagination-tableHeader {
          background-color: #f6f6f6;
          color: #1f1f1f;
          display: flex;
          font: 400 16px Inter, sans-serif;
          justify-content: space-evenly;
          padding: 10px;
          white-space: nowrap;

          .draggable1 {
            cursor: pointer !important;
            width: 20%;
            display: inline-flex;
            align-items: baseline;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }

            .star1 {
              font-size: 14px;
            }
          }

          .draggable2 {
            cursor: pointer !important;
            width: 30%;
            display: flex;
            align-items: baseline;
            justify-content: center;

            .minf {
              font-size: 9px;
            }

            .star1 {
              font-size: 14px;
            }
          }
        }

        .lecture-pagination-data {
          border-collapse: collapse;
          background-color: #fff;
          width: 74vw;
          height: 50vh;
          padding-bottom: 10px;

          .rowItem {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid #f6f6f6;

            .subject {
              display: inline-block;
              // width: 25%;
            }

            .title {
              display: flex;
              display: -webkit-flex;
              width: 30%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;

              .minf {
                font-size: 10px;
                align-content: end;
              }
            }

            .status {
              width: 20%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              font-size: 14px;

              // text-align: center;
              // display: inline-flex;
              .titl {
                width: 75px;
                text-align: center;
                margin-bottom: -18px;
              }

              .minf {
                font-size: 9px;
                width: 75px;
                text-align: center;
              }
            }

            .progressWrapper {
              display: flex;
              align-items: center;
              width: 30%;

              input[type="range" i] {
                background-color: #49b2aa;
                border-radius: 50px;
                height: 100%;
              }

              .progressBar {
                background-color: #fff;
                border: 1px solid #dadada;
                border-radius: 50px;
                height: 15px;
                width: 300px;
                margin-right: 10px;
              }

              .progressFill {
                background-color: #49b2aa;
                border-radius: 50px;
                height: 160%;
                font: 400 14px Inter, sans-serif;
                text-align: right;
              }

              .unstar {
                font-size: 26px;
                width: 10%;
                text-align: center;
                // color: #EFEFEF;
              }

              .star {
                font: 600 26px Inter, sans-serif;
                width: 10%;
                text-align: center;
                color: #F2EE8D;
              }
            }

            .subjectWrapper {
              width: 20%;
              display: inline-flex;
              font-size: 14px;

              .subject {
                border-radius: 50px;
                margin: 0px;
                padding: 4px 10px 4px 10px;
              }

              .agriculture {
                background-color: #f2ee8d;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
              }

              .minf {
                font-size: 9px;
                // margin-left: 5px;
              }

              .minf1 {
                margin-left: 10px;
                display: flex;
                text-overflow: ellipsis;
                flex: none;
                align-items: baseline;

                .minf {
                  font-size: 9px;
                  align-content: end;
                }
              }

              .livestock {
                background-color: #fbcfcf;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
              }
            }

          }

        }

        .lecture-pagination-buttons {
          text-align: end;
          padding: 15px;
          // position: relative;
          background-color: #ffff;
          // width: 74vw;

          .pageButton1 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: -5px 5px -7px 5px;
          }

          .pageButton2 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: 0px 0px -7px 5px;
            ;
          }

          .page-btn {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

          .page-btn-active {
            background-color: #fff;
            border: none;
            border-radius: 50%;
            color: #1e1e1e;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

        }

        .lecture-pagination-buttons button:disabled {
          cursor: not-allowed;
        }

        .lecture-pagination-buttons span {
          margin: 0 10px;
        }
      }

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);
        height: 75vh;

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }
    }

  }
}

@media (min-width: 431px) and (max-width: 991px) {
  .lecture-pagination-buttons {
    text-align: end;
    align-content: center;
    padding: 25px 40px 15px 15px;
    background-color: #ffff;
  }
}

@media (max-width: 430px) {
  .lecture-pagination-buttons {
    text-align: end;
    padding: 10px 15px 15px 10px;
    background-color: #ffff;
  }
}

@media (max-width: 767px) {
  .draggable1 {
    cursor: pointer !important;
    width: 70%;
    display: inline-flex;
    align-items: baseline;
    font-size: 14px;
    justify-content: center;

    .minf {
      font-size: 9px;
    }

    .star1 {
      font-size: 14px;
    }
  }

  .draggable3 {
    cursor: pointer !important;
    width: 30%;
    display: inline-flex;
    align-items: baseline;
    font-size: 14px;
    justify-content: center;

    .minf {
      font-size: 9px;
    }

    .star1 {
      font-size: 14px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .draggable1 {
    cursor: pointer !important;
    width: 90%;
    display: inline-flex;
    align-items: baseline;
    font-size: 14px;
    justify-content: center;

    .minf {
      font-size: 9px;
    }

    .star1 {
      font-size: 14px;
    }
  }

  .draggable3 {
    cursor: pointer !important;
    width: 15%;
    display: inline-flex;
    align-items: baseline;
    font-size: 14px;
    justify-content: center;

    .minf {
      font-size: 9px;
    }

    .star1 {
      font-size: 14px;
    }
  }
}