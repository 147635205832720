
.contentWrapper {
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 100%;
  height: 100vh;
  /* max-width: 100%; */
  flex-direction: column;
  align-items: center;
  padding: 74px 40px;
}

.background {
  background-color: #e8f6f5;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 190px 80px;
}

.formContainer {
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  margin-bottom: -41px;
  width: 580px;
  max-width: 100%;
  /* height: 90vh; */
  flex-direction: column;
  align-items: center;
  padding: 73px 80px 31px;
}

.formContent {
  display: flex;
  margin: 10px;
  /* width: 315px; */
  /* max-width: 100%; */
  flex-direction: column;
}

.gifContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  .loadingGif {
    width: 50px;
    height: 50px;
  }
}

.pageTitle {
  
  text-align: center;
  margin-top: 49px;
  font: 20px Montserrat, sans-serif;
}

.registrationForm {
  width: 100%;
  text-align: center;
}

.emailAddress {
  font-size: 14px;
  margin: 26px;
  text-align: center;
}

.message {
  font-size: 12px;
  align-self: center;
}

.title {
  font-size: 20px;
  text-align: center;
  align-self: center;
  margin: 20px 20px 1px 20px;
  font-weight: 700;
}

.min {
  font-size: 9px;
  text-wrap: nowrap;
  text-align: center;
  margin-bottom: 10px;
}

.mini {
  font-size: 9px;
  text-wrap: nowrap;
}

.label {
  color: #1f1f1f;
  font-size: 14px;
  align-self: start;
  margin-top: 17px;
}

.input {
  border-radius: 5px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 14px;
  padding: 7px 15px;
  border: 1px solid #1f1f1f;
  width: 100%;
  height: 35px;
}

.formWrapper {
  display: flex;
  height: 100%;
  width: 315px;
  max-width: 100%;
  flex-direction: column;
  margin: 10px;
}

.regisContainer {
  display: flex;
  margin-top: 31px;
  width: 100%;
  flex-direction: column;
  /* align-items: center; */
  color: #1f1f1f;
  
  padding: 10px;
}

.regisWrapper {
  display: flex;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  /* letter-spacing: -0.58px; */
  margin-bottom: 15px;
}

.checkbox {
  border-radius: 2px;
  background-color: #fff;
  align-self: start;
  display: flex;
  width: 15px;
  height: 15px;
  border: 1px solid #333;
}

.regisTextDiv {
  flex-basis: auto;
  font-size: 12px;
  text-wrap: nowrap;
  width: 100%;
  min-width: 270px;
}

.regisText {
  flex-basis: auto;
  display: flex;
  font-size: 12px;
  text-wrap: nowrap;
  width: 100%;
  min-width: 270px;
  align-items: baseline;
}
.instructions1 {
  flex-wrap: wrap;
  font-size: 12px;
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: center;
}

.instructions {
  font-size: 12px;
  display: inline-flex;
  align-items: baseline;
  text-wrap: wrap;
  flex-wrap: wrap;
  width: 100vw;
}
.instructions2 {
  font-size: 12px;
  align-items: baseline;
  text-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 50px;
  text-align: center;
}
.instructions3 {
  font-size: 14px;
  align-items: baseline;
  text-wrap: wrap;
  flex-wrap: wrap;
  padding: 50px;
  text-align: center;
}
.inst {
  font-size: 13px;
  text-wrap: nowrap;
  cursor: pointer;
}

.instructionsDiv {
  margin: 28px 0 28px 0;
}

.submitButton {
  border-radius: 24px;
  background-color: rgba(73, 178, 170, 0.6);
  align-self: center;
  margin-top: 19px;
  width: 100%;
  font-size: 14px;
  color: #fff;
  height: 45px;
  padding: 5px 30px;
  border: none;
}
.submitButtonActive {
  border-radius: 24px;
  background-color: #49b2aa;
  align-self: center;
  margin-top: 19px;
  /* width: 261px; */
  width: 100%;
  font-size: 14px;
  color: #fff;
  height: 45px;
  padding: 2px 32px;
  border: none;
}

.disclaimer {
  margin: 17px;
  font: 14px Inter, sans-serif;
}

@media (max-width: 991px) {
  .background {
    max-width: 100%;
    padding: 100px 20px;
  }
  .formContainer {
    margin: 10px;
    padding: 10px;
    /* height: 100vh; */
  }
  .message {
    padding: 0px;
  }
  .input {
    padding-right: 20px;
  }
  .regisContainer {
    padding: 0px;
  }

  .instructions {
    font-size: 12px;
    text-wrap: wrap;
    width: 100%;
    min-width: 270px;
    justify-content: flex-start;
    align-items: baseline;
  }
  .instructions2 {
    font-size: 12px;
    text-wrap: wrap;
    width: 100%;
    min-width: 270px;
    justify-content: center;
    text-align: center;
    align-items: baseline;
  }
  .instructions3 {
    font-size: 14px;
    align-items: baseline;
    text-wrap: wrap;
    flex-wrap: wrap;
    padding: 50px;
    text-align: center;
  }
}

@media (max-width: 400px) {
  .instructions {
    font-size: 12px;
    text-wrap: wrap;
    width: 100%;
    min-width: 270px;
    justify-content: flex-start;
    align-items: baseline;
  }
  .instructions1 {
    flex-wrap: wrap;
  }
  .instructions {
    font-size: 12px;
    text-wrap: wrap;
    width: 100%;
    min-width: 270px;
    justify-content: center;
    align-items: baseline;
  }
}