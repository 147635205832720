@import '../basics/variables';
@import '../basics/mixins';

.home-page {
  overflow: auto;
  .detect {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    background-color: rgba($color: #515151, $alpha: .5);
    opacity: 0.8;
    width: 50%;
    height: 150px;
    line-height: 150px;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    font-size: 30px;
  }

  .main {
    margin: 0;

    .main-container {
      display: flex;
      // margin: 70px 0px 0px 0px;
      // height: 80vh;
    }

  }
}