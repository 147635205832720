.VideoPlayer {
  // padding: 10px;
  position: relative;

  .video {
    width: 100%;
    height: 100%;
    background: #f6f6f6;
    object-fit: cover;
  }

  .videoControls {
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    background-color: #f6f6f6;
    justify-content: space-between;
    display: flex;
    width: -webkit-fill-available;

    // gap: 1rem;
    .control-group-btn {
      display: flex;
      display: -webkit-flex;
      justify-content: start;

      .range-input {
        display: block;
        transform: rotate(0deg);
        // width: 45vw;
        height: 15px;
        background-color: #49b2aa;
        border-radius: 20px;
        cursor: pointer;
      }

      .control-button {
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 1.5rem;
        // margin: 0 0.5rem;
        cursor: pointer;
        // display: flex;
        align-items: center;

      }
    }

    .controlGroup1 {
      display: -webkit-flex;
      -webkit-justify-content: space-between;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: -webkit-fill-available;

      .control-group-btn {
        display: flex;
        display: -webkit-flex;

        .range-input {
          -webkit-appearance: none;
          appearance: none;
          // margin-right: 15px;
          height: 12px;
          background: lightgray;
          border-radius: 50px;
          background-image: linear-gradient(gray, gray);
          background-repeat: no-repeat;
          cursor: pointer;
        }

      }

      .control-group-btn2 {
        display: flex;
        display: -webkit-flex;
        text-wrap: nowrap;
        align-items: center;
        margin-right: 2px;
        .loadingGif {
          width: 28px;
        }
        .minf {
          display: flex;
          display: -webkit-flex;
          flex-wrap: nowrap;
          // font-size: 12px;
        }
        &:hover {
          .volume-input {
            display: block;
            transform: rotate(-90deg);
            bottom: 85px;
            position: absolute;
            left: -15px;
          }
        }

        .volume-input {
          display: none;
          transform: rotate(-90deg);
          bottom: 85px;
          position: absolute;
          left: -15px;
        }

        .control-button2 {
          background-color: transparent;
          border: none;
          outline: none;
          font-size: 1.5rem;
          cursor: pointer;
          display: flex;
          display: -webkit-flex;
          align-items: center;
        }
      }
      .control-group-btn4 {
        display: flex;
        display: -webkit-flex;
        // width: -webkit-fill-available;
        /* For older iOS Safari */
        .range-input {
          -webkit-appearance: none;
          appearance: none;
          height: 12px;
          background: lightgray;
          border-radius: 50px;

          background-image: linear-gradient(gray, gray);
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }
      .control-group-slider {
        display: -webkit-flex;
        display: flex;
        align-items: center;
        width: -webkit-fill-available;


        .control-group-btn3 {
          display: flex;
          display: -webkit-flex;
          width: -webkit-fill-available;
          /* For older iOS Safari */
          .range-input {
            -webkit-appearance: none;
            appearance: none;
            height: 12px;
            background: lightgray;
            border-radius: 50px;

            background-image: linear-gradient(gray, gray);
            background-repeat: no-repeat;
            cursor: pointer;
          }
        }

        .control-button1 {
          background-color: transparent;
          border: none;
          outline: none;
          font-size: 1.5rem;
          // margin: 0 0.5rem;
          cursor: pointer;
          display: flex;
          display: -webkit-flex;
          /* For older iOS Safari */
          align-items: center;

          .loadingGif {
            width: 28px;
          }
        }

        // width: 30%;
        .time {
          min-width: max-content;
          align-self: center;
        }


        .control-group-volume {
          // display: contents;
          display: -webkit-flex;
          /* For older iOS Safari */

          // gap: 10px;
          .loadingGif {
            width: 28px;
          }

          &:hover {
            .volume-input {
              display: block;
              transform: rotate(-90deg);
              bottom: 85px;
              position: absolute;
              // left: -25px;
            }
          }

          .volume-input {
            display: none;
            transform: rotate(-90deg);
            bottom: 85px;
            position: absolute;
            left: -23px;
          }

          .control-button2 {
            background-color: transparent;
            border: none;
            outline: none;
            font-size: 1.5rem;
            cursor: pointer;
            display: flex;
            display: -webkit-flex;
            align-items: center;
          }
        }

      }
    }
  }

}

@media (min-width: 991px) {
  .range-input {
    margin: 10px;
    width: 40vw;
  }
}

@media (min-width: 640px) and (max-width: 990px) {
  .range-input {
    width: 65vw;
  }
}

@media (min-width: 431px) and (max-width: 639px) {
  .range-input {
    width: 50vw;
  }
}

@media (min-width: 376px) and (max-width: 430px) {
  .range-input {
    width: 40vw;
  }
}

@media (min-width: 361px) and (max-width: 375px) {
  .range-input {
    width: 38vw;
  }
}

@media (max-width: 360px) {
  .range-input {
    width: 30vw;
  }
}