@media (min-width: 992px) {
  .supplementary {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 80%;

    .supplementary-title {
      padding: 30px 15px 30px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .supplementary-heading {
        display: inline-flex;
        align-items: baseline;
        font: 600 16px Inter, sans-serif;
        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .supplementary-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }

    .supplementary-container {
      width: 100%;

      .supplementary-search {
        // position: relative;
        display: flex;
        gap: 100px;
        padding: 15px 15px 5px 15px;

        .supplementary-searchField {
          display: flex;
          align-items: center;

          .supplementary-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font: 400 14px Inter, sans-serif;
            height: 35px;
            padding: 0 10px;
            width: 280px;
            // z-index: -1;
          }

          .supplementary-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
          }
        }

        .supplementary-statusFilter {
          display: flex;
          align-items: center;

          .supplementary-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font: 400 14px Inter, sans-serif;
            height: 35px;
            padding: 0 10px;
            width: 280px;
            // z-index: -1;
          }

          .supplementary-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
          }
        }
      }

      .supplementary-pagination {
        // height: 72vh;
        padding: 15px;
        overflow-y: scroll;

        .supplementary-pagination-tableHeader {
          background-color: #f6f6f6;
          color: #1f1f1f;
          display: flex;
          font: 400 16px Inter, sans-serif;
          justify-content: space-evenly;
          padding: 10px;
          white-space: nowrap;

          .draggable1 {
            cursor: pointer !important;
            width: 10%;
            display: inline-flex;
            justify-content: center;
            align-items: baseline;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }

            .star1 {
              font-size: 14px;
            }
          }

          .draggable2 {
            cursor: pointer !important;
            width: 40%;
            display: inline-flex;
            align-items: baseline;
            font-size: 14px;
            justify-content: center;
            .minf {
              font-size: 9px;
            }

            .star1 {
              font-size: 14px;
            }
          }
        }

        .supplementary-pagination-data {
          border-collapse: collapse;
          background-color: #fff;

          .rowItem {
            cursor: pointer;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding: 10px 6px 10px 6px;
            border-bottom: 1px solid #f6f6f6;

            .subject {
              display: inline-block;
              // width: 25%;
            }

            .title {
              width: 40%;
              flex-wrap: wrap;
              font-size: 14px;
              align-items: baseline;
              display: flex;
              display: -webkit-flex;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              .minf {
                font-size: 10px;
                align-content: end;
              }
            }

            .status {
              width: 40%;
              font-size: 14px;
              align-items: baseline;
              display: flex;
              display: -webkit-flex;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              .minf {
                font-size: 9px;
                align-content: end;
              }
            }

            .subjectWrapper {
              width: 10%;
              align-items: center;
              justify-content: center;
              display: inline-flex;
              font-size: 14px;

              .subject {
                border-radius: 50px;
                margin: 0px;
                padding: 4px 10px 4px 10px;
              }

              .agriculture {
                background-color: #f2ee8d;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
                padding: 4px 20px 4px 20px;
              }

              .minf {
                font-size: 9px;
                // margin-left: 5px;
              }

              .minf1 {
                margin: 10px;
                display: flex;
                align-items: baseline;
                .minf {
                  font-size: 9px;
                  align-content: center;
                }
              }

              .livestock {
                background-color: #fbcfcf;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
                padding: 4px 20px 4px 20px;
              }
            }

          }

        }

        .supplementary-pagination-buttons {
          text-align: end;
          padding: 10px 20px 15px 15px;
          // position: relative;
          background-color: #ffff;

          .pageButton1 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: -5px 5px -7px 5px;
          }

          .pageButton2 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: 0px 0px -7px 5px;
            ;
          }

          .page-btn {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

          .page-btn-active {
            background-color: #fff;
            border: none;
            border-radius: 50%;
            color: #1e1e1e;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

        }

        .supplementary-pagination-buttons button:disabled {
          cursor: not-allowed;
        }

        .supplementary-pagination-buttons span {
          margin: 0 10px;
        }
      }

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);
        height: 81vh;
        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }
    }

  }
}

@media (max-width: 991px) {
  .supplementary {
    background-color: #e8f6f5;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;

    .supplementary-title {
      padding: 20px 15px 20px 15px;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;

      .supplementary-heading {
        display: inline-flex;
        align-items: baseline;
        font: 600 16px Inter, sans-serif;
        .minf {
          font: 600 9px Inter, sans-serif;
        }
      }
    }

    .supplementary-line {
      background-color: #000;
      border-color: #e8f6f5;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }

    .supplementary-container {
      width: 100%;

      .supplementary-search {
        margin: 0 10px 0 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow-y: visible;

        .supplementary-searchField {
          display: flex;
          align-items: center;

          .supplementary-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font: 400 14px Inter, sans-serif;
            height: 35px;
            padding: 0 10px;
            width: 280px;
            // z-index: -1;
          }

          .supplementary-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
          }
        }

        .supplementary-statusFilter {
          display: flex;
          align-items: center;

          .supplementary-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font: 400 14px Inter, sans-serif;
            height: 35px;
            padding: 0 10px;
            width: 280px;
            // z-index: -1;
          }

          .supplementary-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
          }
        }
      }

      .supplementary-pagination {
        padding: 10px;
        overflow-y: scroll;

        .supplementary-pagination-tableHeader {
          background-color: #f6f6f6;
          color: #1f1f1f;
          display: flex;
          font: 400 16px Inter, sans-serif;
          justify-content: space-evenly;
          padding: 10px;
          white-space: nowrap;
        }

        .supplementary-pagination-data {
          border-collapse: collapse;
          background-color: #fff;
          height: 55vh;
          overflow-y: scroll;

          .rowItem {
            cursor: pointer;
            padding: 4px;
            margin: 4px;
            border-bottom: 1px solid #f6f6f6;

            .subject {
              display: inline-block;
              // width: 25%;
            }

            .title {
              // width: 25%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              display: flex;
              align-items: baseline;
              .minf {
                font-size: 10px;
                align-content: end;
              }
            }

            .status {
              // width: 25%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              margin: 10px;
              display: flex;
              align-items: baseline;
              font-size: 14px;

              .minf {
                font-size: 9px;
                align-content: end;
              }
            }

            .subjectWrapper {
              gap:10px;
              width: 100%;
              align-items: center;
              display: inline-flex;
              font-size: 14px;

              .subject {
                border-radius: 50px;
                margin: 0px;
                padding: 4px 10px 4px 10px;
              }

              .agriculture {
                background-color: #f2ee8d;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
              }

              .minf {
                font-size: 9px;
                // margin-left: 5px;
              }

              .minf1 {
                width: 70%;
                display: flex;
                display: -webkit-flex;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                .minf {
                  font-size: 9px;
                  align-content: center;
                }
              }

              .livestock {
                background-color: #fbcfcf;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
              }
            }

          }

        }

        .supplementary-pagination-buttons {
          .pageButton1 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: -5px 5px -7px 5px;
          }

          .pageButton2 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: 0px 0px -7px 5px;
            ;
          }

          .page-btn {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

          .page-btn-active {
            background-color: #fff;
            border: none;
            border-radius: 50%;
            color: #1e1e1e;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

        }

        .supplementary-pagination-buttons button:disabled {
          cursor: not-allowed;
        }

        .supplementary-pagination-buttons span {
          margin: 0 10px;
        }
      }

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);
        height: 81vh;

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }
    }

  }
}

@media (min-width: 360px) and (max-height: 740px) {
  .supplementary {
    .supplementary-container {
      .supplementary-search {
        .supplementary-searchField {
          .supplementary-titleInput {
            width: 220px;
          }
        }

        .supplementary-statusFilter {
          .supplementary-statusSelect {
            width: 220px;
          }
        }
      }

      .supplementary-pagination {
        .supplementary-pagination-data {
          height: 64vh;
        }
      }
    }


  }
}

@media (min-width: 375px) and (max-height: 812px) {
  .supplementary {
    .supplementary-container {
      .supplementary-search {
        .supplementary-searchField {
          .supplementary-titleInput {
            width: 260px;
          }
        }

        .supplementary-statusFilter {
          .supplementary-statusSelect {
            width: 260px;
          }
        }
      }

      .supplementary-pagination {
        .supplementary-pagination-data {
          height: 60vh;
        }
      }
    }


  }
}

@media (min-width: 390px) and (max-height: 932px) {
  .supplementary {
    .supplementary-container {
      .supplementary-pagination {
        .supplementary-pagination-data {
          height: 71vh;
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: 430px) and (max-height: 932px) {
  .supplementary {
    .supplementary-pagination {
      .supplementary-pagination-data {
        height: 64vh;
        padding-bottom: 10px;
      }

    }
  }
}

@media (max-width: 430px) and (max-height: 667px) {
  .supplementary {
    .supplementary-title {
      padding: 20px 15px 20px 15px;
    }

    .supplementary-container {
      width: 100%;

      .supplementary-search {
        margin: 0 10px 0 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow-y: visible;

        .supplementary-searchField {
          display: flex;
          align-items: center;

          .supplementary-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 260px;
            // z-index: -1;
          }

          .supplementary-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: center;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }
          }
        }

        .supplementary-statusFilter {
          display: flex;
          align-items: center;

          .supplementary-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 260px;
            // z-index: -1;
          }

          .supplementary-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
            font-size: 14px;
          }
        }
      }

      .supplementary-pagination {
        .supplementary-pagination-data {
          height: 61vh;
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-height: 1180px) {
  .supplementary {
    .supplementary-container {
      .supplementary-search {
        .supplementary-searchField {
          .supplementary-titleInput {
            width: 220px;
          }
        }

        .supplementary-statusFilter {
          .supplementary-statusSelect {
            width: 220px;
          }
        }
      }

      .supplementary-pagination {
        .supplementary-pagination-data {
          height: 75vh;
          overflow-y: scroll;
        }
      }
    }


  }
}

@media (min-height: 1366px) {
  .supplementary {

    .supplementary-container {
      width: 100%;

      .supplementary-search {
        // position: relative;
        width: 75vw;
        display: flex;
        gap: 100px;
        padding: 15px 15px 5px 15px;

        .supplementary-searchField {
          display: flex;
          align-items: center;

          .supplementary-titleInput {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 250px;
            // z-index: -1;
          }

          .supplementary-filterLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: center;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }
          }
        }

        .supplementary-statusFilter {
          display: flex;
          align-items: center;

          .supplementary-statusSelect {
            border: 1px solid #dadada;
            border-radius: 5px;
            font-size: 14px;
            height: 35px;
            padding: 0 10px;
            width: 220px;
            // z-index: -1;
          }

          .supplementary-statusLabel {
            margin-right: 10px;
            flex-wrap: nowrap;
            width: 80px;
            text-align: start;
            font-size: 14px;
          }
        }
      }

      .supplementary-pagination {
        // height: 100%;
        padding: 15px;
        overflow-y: scroll;

        .supplementary-pagination-tableHeader {
          background-color: #f6f6f6;
          color: #1f1f1f;
          display: flex;
          font: 400 16px Inter, sans-serif;
          justify-content: space-evenly;
          padding: 10px;
          white-space: nowrap;

          .draggable1 {
            cursor: pointer !important;
            width: 25%;
            display: inline-flex;
            align-items: baseline;
            font-size: 14px;

            .minf {
              font-size: 9px;
            }

            .star1 {
              font-size: 14px;
            }
          }
        }

        .supplementary-pagination-data {
          border-collapse: collapse;
          background-color: #fff;
          width: 74vw;
          height: 55vh;
          padding-bottom: 10px;

          .rowItem {
            cursor: pointer;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding: 6px;
            border-bottom: 1px solid #f6f6f6;

            .subject {
              display: inline-block;
              // width: 25%;
            }

            .title {
              width: 40%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              display: flex;
              align-items: baseline;
              .minf {
                font-size: 10px;
                align-content: end;
              }
            }

            .status {
              width: 40%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              display: flex;
              align-items: baseline;
              font-size: 14px;

              .minf {
                font-size: 9px;
                align-content: end;
              }
              // text-align: center;
              // display: inline-flex;
              .titl {
                width: 75px;
                text-align: center;
                margin-bottom: -18px;
              }

              .minf {
                font-size: 9px;
                width: 75px;
                text-align: center;
              }
            }

            .subjectWrapper {
              width: 25%;
              display: inline-flex;
              font-size: 14px;

              .subject {
                border-radius: 50px;
                margin: 0px;
                padding: 4px 10px 4px 10px;
              }

              .agriculture {
                background-color: #f2ee8d;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
              }

              .minf {
                font-size: 9px;
                // margin-left: 5px;
              }

              .minf1 {
                margin: 10px;
                display: flex;
                align-items: baseline;
                .minf {
                  font-size: 9px;
                  align-content: center;
                }
              }

              .livestock {
                background-color: #fbcfcf;
                text-wrap: nowrap;
                display: inline-flex;
                align-items: baseline;
              }
            }

          }

        }

        .supplementary-pagination-buttons {
          text-align: end;
          padding: 15px;
          // position: relative;
          background-color: #ffff;
          width: 74vw;

          .pageButton1 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: -5px 5px -7px 5px;
          }

          .pageButton2 {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 16px Inter, sans-serif;
            height: 24px;
            width: 25px;
            margin: 0px 0px -7px 5px;
            ;
          }

          .page-btn {
            background-color: #49b2aa;
            border: none;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

          .page-btn-active {
            background-color: #fff;
            border: none;
            border-radius: 50%;
            color: #1e1e1e;
            cursor: pointer;
            font: 400 14px Inter, sans-serif;
            height: 24px;
            width: 24px;
            margin: -5px 5px -5px 5px;
            padding: 0;
          }

        }

        .supplementary-pagination-buttons button:disabled {
          cursor: not-allowed;
        }

        .supplementary-pagination-buttons span {
          margin: 0 10px;
        }
      }

      .gif-container {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-top: calc(40vh - 60px);
        height: 81vh;

        .loading-gif {
          width: 50px;
          height: 50px;
        }
      }
    }

  }
}

@media (min-width: 431px) and (max-width: 991px){
  .supplementary-pagination-buttons {
    text-align: end;
    align-content: center;
    padding: 25px 40px 15px 15px;
    background-color: #ffff;
  }
}

@media (max-width: 430px) {
  .supplementary-pagination-buttons {
    text-align: end;
    padding: 15px 15px 15px 8px;
    background-color: #ffff;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .draggable1 {
    cursor: pointer !important;
    width: 90%;
    display: inline-flex;
    align-items: baseline;
    font-size: 14px;
    justify-content: center;

    .minf {
      font-size: 9px;
    }

    .star1 {
      font-size: 14px;
    }
  }

  .draggable3 {
    cursor: pointer !important;
    width: 15%;
    display: inline-flex;
    align-items: baseline;
    font-size: 14px;
    justify-content: center;

    .minf {
      font-size: 9px;
    }
    .star1 {
      font-size: 14px;
    }
  }
}